import React, { useState, useEffect,useRef } from 'react';

import { ReactComponent as FilterIcon } from "../../assets/img/filter.svg";
import { ReactComponent as EditIcon } from '../../assets/img/Edit.svg';
import { ReactComponent as ViewIcon } from '../../assets/img/visibility.svg';
import AddTrial from './AddTrial';
import { useNavigate} from "react-router-dom";
import AddTrialOffline from './AddTrialOffline';
import configParam from '../../config';
import { ReactComponent as DeleteIcon } from '../../assets/img/Delete.svg';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import {  IconButton, Typography,  Grid, Snackbar } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import { useRecoilState, useRecoilValue } from "recoil";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import {
 
  Button,
 
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card, CardContent,
  Menu,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ImageIcon from '@mui/icons-material/Image';
import axios from "axios";
import Chip from '@mui/material/Chip';
import { v4 as uuidv4 } from 'uuid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { plantId } from '../../recoil/atoms';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { userPlantState,userLocationState } from '../../recoil/atoms';
import { useLocation } from 'react-router-dom';

const allAreas = ["Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3","Cullet3"];
const allZones = ["g9", "g12","zone test cullet 7"]; 




const cardStyle = {
  width: '100%',
  height: '168px',
  marginBottom: '10px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
 
  marginRight: '18px', // Add margin to the right of each card
  

};
const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
};
const topRightCornerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '15px',
};


const access_token=localStorage.getItem("accesstoken");

const View = () => {
  const [open, setOpen] = useState(false);
  const [openOffline, setOpenOffline] = useState(false);
  const [userDet, setUserDet] = useState({});
  const [area, setArea] = useState(null);
  const navigate = useNavigate();
  const [description, setDescription] = useState('');
  const [zone, setZone] = useState('');
  const [xrfNumber, setXrfNumber] = useState('');
  const [Ni, setNi] = useState('');
  const [remarksValue, setRemarksValue] = useState('');
  const [comments, setComments] = useState('');

  const [expanded, setExpanded] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [OnlineAreaData,setOnlineAreaData]=useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [plantData, setPlantData] = useState([])
  const [trialData, setTrialData] = useState([]);
  const [currenttrialData, setCurrentTrialData] = useState([]);
  const [trialTableData, setTrialTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
 
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openViewIconModal, setOpenViewIconModal] = useState(false);
 
  const [selectedImage, setSelectedImage] = useState(null);

  const [imageFileData, setImageFileData] = useState('');
  const [percentage,setPercentage]=useState(0);
  const [expandedRowData, setExpandedRowData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalOpenOffline, setConfirmationModalOpenOffline] = useState(false);
  const [confirmationMobileModalOpen, setConfirmationMobileModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [iconsVisible, setIconsVisible] = useState(false);
  const [iconsVisibleTrial, setIconsVisibleTrial] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [currentViewData, setCurrentViewData] = useState([])
  const [currentHistoryViewData, setCurrentHistoryViewData] = useState([]);
  const [currentHistoryImageData, setCurrentHistoryImageData] = useState([]);
  const [PlantId, setPlantId] = useRecoilState(plantId);
  const datauser=localStorage.getItem("user_id")
  let data = localStorage.getItem('user');
  let User = JSON.parse(data);
  const userlogoutdata = datauser!=null?datauser:User.plant_id[0];
  const [menuAnchors, setMenuAnchors] = useState([]); 
  const [currentTrialDelete,setCurrentTrialDelete]=useState(null);
  const [searchText, setSearchText] = useState('');
  const [filterAreaList,setFilterAreaList]=useState([])
  const [filterZoneList,setFilterZoneList]=useState([])
  const [selectedAreas, setSelectedAreas] = useState([]); // Initialize with an empty array
  const [selectedZones, setSelectedZones] = useState([]);
  const [joined,setJoined]=useState([])
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [OnlineTest, setOnlineTest] = useState();
  const [zoneTableData, setZoneTableData] = useState([]);
  const [groupedRows, setGroupedRows] = useState({});
  const [trialOfflineData, setTrialOfflineData] = useState([]);
  const [selectAllAreas, setSelectAllAreas] = useState(false);
  const [selectAllZones, setSelectAllZones] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [db, setDb] = useState(null);
  const [loading, setLoading] =useState(false);
  const[offlineInspectionlength,setofflineInspectionlength]=useState(localStorage.getItem('lenofflinedata') || '0');
  const offb=localStorage.getItem('lenofflinedata');
  const placeholderImageUrl = '';
  const [filterState,setFilterState]=useState(true)

  const allzonedetails = localStorage.getItem('allzoneData');

  // Parse the string back to an array using JSON.parse()
  const storedzoneArray = JSON.parse(allzonedetails);
 
  const allareadetails = localStorage.getItem('allareaData');

  // Parse the string back to an array using JSON.parse()

  
  // useEffect(() => {
  //   if(isOnline){    
  //     deleteAllDataFromArea(db,areaName)
  //     deleteAllDataFromZone(db,zoneName)
  //   }
  //   }, [isOnline]);
  
  const handleIconClick = (event) => {
    setDialogOpen(true);
  };
  useEffect(()=>{
    indexedDB.databases().then(databases => {
      const numberOfDatabases = databases.length;
   
      databases.forEach(db => {
       
      });
      if(databases.find((option)=>option.name==='nis')===undefined){
        localStorage.setItem("lenofflinedata",0)
        setofflineInspectionlength(0)
      }})
  },[])
  useEffect(() => {
    const handleOnline = () => {
       
        if (!isOnline) {
            setIsOnline(true);
              localStorage.setItem("onlinestate",1)
         
            
          
            window.location.reload();
        }
     
        
    };

    const handleOffline = () => {
        localStorage.setItem("onlinestate",0)
        // setIsOnlines(false)
        setIsOnline(false);
        // navigate('/reports')
    };

   
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
     
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
    };
}, [isOnline]);

// useEffect(()=>{
//   if(isOnline){
//     const connection = navigator.connection;
//     const downlinkSpeedMbps = connection.downlink * 8; // Convert to Mbps
//     const effectiveType = connection.effectiveType; // '4g', '3g', etc.
//     console.log('Downlink Speed:', downlinkSpeedMbps, 'Mbps');
//     console.log('Effective Connection Type:', effectiveType); 
//                 // setIsOnline(true);
//                 if(downlinkSpeedMbps>8){
//                   localStorage.setItem("onlinestate",1)
//                   console.log('Reloading page...');
//                   indexedDB.databases().then(databases => {
//                     const numberOfDatabases = databases.length;
//                     console.log(`Number of IndexedDB databases: ${databases.length}`);
//                     databases.forEach(db => {
//                       console.log(`Database name: ${db.name}, Version: ${db.version}`);
//                     });
//                     if(databases.find((option)=>option.name==='nis')===undefined){
//                       // localStorage.setItem("offlinedbinserttest",0)
//                     }})
//                 }
//                 else{
//                   localStorage.setItem("onlinestate",1)
//                   console.log('Reloading page...');
//                   indexedDB.databases().then(databases => {
//                     const numberOfDatabases = databases.length;
//                     console.log(`Number of IndexedDB databases: ${databases.length}`);
//                     databases.forEach(db => {
//                       console.log(`Database name: ${db.name}, Version: ${db.version}`);
//                     });
//                     if(databases.find((option)=>option.name==='nis')!==undefined){
//                       // localStorage.setItem("offlinedbinserttest",1)
//                       const request = indexedDB.open(databases[0].name); 
//                       request.onsuccess = function(event) {
//                         setConfirmationModalOpenOffline(true)
//                       }
//                       request.onerror = function(event) {
//                         console.log("event",event)
//                       }
//                     }
//                     else{
//                       // localStorage.setItem("offlinedbinserttest",0)
//                     }
//                   })
                 
//                 }
//   }
  
// },[isOnline])

useEffect(() => {
  if (confirmationModalOpenOffline) {
      const timer = setTimeout(() => {
          setConfirmationModalOpenOffline(false);
      }, 4000); // Auto-close after 5 seconds (5000 milliseconds)

      return () => {
          clearTimeout(timer);
      };
  }
}, [confirmationModalOpenOffline]);

const storedareaArray = JSON.parse(allareadetails);
  const dbName = 'nis';
  const areaName = 'area';
  const zoneName='zone';
  const imageName='image';
  const trialName='trial';
  const trialDetailsName='trialDetails';

  useEffect(() => {
  if(!isOnline){
   
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const database = event.target.result;
      database.createObjectStore(areaName, { keyPath: 'id', autoIncrement: true });
      database.createObjectStore(zoneName, { keyPath: 'id', autoIncrement: true });
      database.createObjectStore(imageName, { keyPath: 'id', autoIncrement: true });
      database.createObjectStore(trialName, { keyPath: 'id', autoIncrement: true });
      database.createObjectStore(trialDetailsName, { keyPath: 'id', autoIncrement: true });
    };

    request.onsuccess = (event) => {
    
        const database = event.target.result;
        const transaction = database.transaction(['trial', 'trialDetails', 'area', 'zone','image'], 'readwrite');
        const objectStore = transaction.objectStore('zone');
        const areaStore = transaction.objectStore('area');
        const getAllRequest = objectStore.getAll();
        const getAllAreaRequest = areaStore.getAll();
      
        getAllAreaRequest.onsuccess=()=>{
        
            const allareadata=getAllAreaRequest.result;
            const allData = getAllRequest.result;
           
            allareadata.forEach(area => {
              if (!storedareaArray.some(storedArea => storedArea.id === area.id && storedArea.plant_id===area.plant_id && storedArea.area===area.area)) {
                areaStore.delete(area.id);
           
              }
            });
            if(allData.length>0 && allareadata.length>0){
              const filteredArray = storedzoneArray.filter(zone => !allData.some(z => z.area_id === zone.area_id && z.zone===zone.zone))
              const filteredAreaArray = storedareaArray.filter(zone => !allareadata.some(z => z.id === zone.id && z.plant_id===zone.plant_id && z.area===zone.area));
           
             if(filteredArray.length>0 && filteredAreaArray.length>0){
              setDb(database);
             
              // if(localStorage.getItem("offlinedbinserttest")==='0'){
                filteredArray.forEach(data => {
                  const uuid = uuidv4();
                  // Assign the UUID as the key for the item
                  const itemWithUuidKey = { ...data, id: uuid };
                  objectStore.add(itemWithUuidKey);
                });
                filteredAreaArray.forEach(data => {
                  areaStore.add(data);
                });
              // insertDataIntoZone(database,storedzoneArray,finalzonelist)
             }else if(filteredArray.length>0 && filteredAreaArray.length===0){
         
              setDb(database);
              // if(localStorage.getItem("offlinedbinserttest")==='0'){
                filteredArray.forEach(data => {
                  const uuid = uuidv4();
                  // Assign the UUID as the key for the item
                  const itemWithUuidKey = { ...data, id: uuid };
                  objectStore.add(itemWithUuidKey);
                });
             }else if(filteredArray.length===0 && filteredAreaArray.length>0){
            
              setDb(database);
              // if(localStorage.getItem("offlinedbinserttest")==='0'){
                filteredAreaArray.forEach(data => {
                
                  areaStore.add(data);
                });
             } else{
          
              setDb(database);
             }

            
            }
            else{
             
              setDb(database);
              // if(localStorage.getItem("offlinedbinserttest")==='0'){
              insertDataIntoarea(database,storedareaArray,areaName)
              insertDataIntoZone(database,storedzoneArray,zoneName)  
            }
          
        
            // Process the 'allData' array as needed (e.g., display, manipulate, etc.)
          
    
        
        }
    
        getAllAreaRequest.onerror = (event) => {
        
          console.error('Error getting data from the area table in IndexedDB', event);
        };
      
      // }
      // deleteAllDataFromArea(database,areaName)
      // deleteAllDataFromZone(database,zoneName)
    };

    request.onerror = (event) => {
      console.error('Error opening IndexedDB', event);
    };
  }
  }, [isOnline]);


  const insertDataIntoarea = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
  
    dataArray.forEach(data => {
      objectStore.add(data);
    });
  
    transaction.oncomplete = () => {
    
    };
  
    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event);
    };
  };
  const insertDataIntoZone = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
  
    dataArray.forEach(data => {
      // Generate a UUID for each item
      const uuid = uuidv4();
      // Assign the UUID as the key for the item
      const itemWithUuidKey = { ...data, id: uuid };
      objectStore.add(itemWithUuidKey);
    });
  
    transaction.oncomplete = () => {
     
    };
  
    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event);
    };
  };
 
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleCategoryChange = (event, category) => {
    if (category === 'area') {
      // Handle the 'Select All Areas' checkbox
      if (event.target.value.includes('all')) {
        setSelectAllAreas(event.target.checked);
        setSelectedAreas(event.target.checked ? filterAreaList.map(areaObj => areaObj.area) : []);
      } else {
        setSelectedAreas((prevSelectedAreas) => {
          const newSelectedAreas = [...prevSelectedAreas];
          if (event.target.checked) {
            newSelectedAreas.push(event.target.value);
          } else {
            const index = newSelectedAreas.indexOf(event.target.value);
            if (index !== -1) {
              newSelectedAreas.splice(index, 1);
            }
          }
          return newSelectedAreas;
        });
        setSelectAllAreas(false);
      }
    } else if (category === 'zone') {
      // Handle the 'Select All Zones' checkbox
      if (event.target.value.includes('all')) {
        setSelectAllZones(event.target.checked);
        const selectedZonesFromFilter = filterZoneList.map(zoneObj => zoneObj.zone);
        setSelectedZones(event.target.checked ? selectedZonesFromFilter : []);
      } else {
        setSelectedZones((prevSelectedZones) => {
          const newSelectedZones = [...prevSelectedZones];
          if (event.target.checked) {
            newSelectedZones.push(event.target.value);
          } else {
            const index = newSelectedZones.indexOf(event.target.value);
            if (index !== -1) {
              newSelectedZones.splice(index, 1);
            }
          }
          return newSelectedZones;
        });
        setSelectAllZones(false);
      }
    }
  };

  useEffect(() => {
    gettrialFilterZoneDetails();
  }, [selectedAreas]);
 
  
  const filteredData = trialData.filter((data) => {
    const searchString = searchText.toLowerCase();
    const selectedAreaLowerCase = Array.isArray(selectedAreas)
      ? selectedAreas.map(area => area.toLowerCase())
      : [];
  
    const selectedZoneLowerCase = Array.isArray(selectedZones)
      ? selectedZones.map(zone => zone.toLowerCase())
      : [];
  
   
    
    const isMatch = (
      (selectedAreaLowerCase.length === 0 || selectedAreaLowerCase.includes(data.area.toLowerCase())) &&
      (selectedZoneLowerCase.length === 0 || selectedZoneLowerCase.includes(data.zone.toLowerCase())) &&
      (
        data.area.toLowerCase().includes(searchString) ||
        data.zone.toLowerCase().includes(searchString) ||
        (data.formatted_date && data.formatted_date.includes(searchString)) ||
        data.name.toLowerCase().includes(searchString)
      )
    );
    
      
   
  
    return isMatch;
  });
  

  const [plantDet, setPlantDet] = useState('');
  const [breadcrumb, setBreadcrumb] = useState('');
  const location = useLocation();
  const userPlant = useRecoilValue(userPlantState);
  const userLocation=useRecoilValue(userLocationState);
  const [locationDet, setLocationDet] = useState({});
  useEffect(() => {
    // Extract the last part of the current path as the breadcrumb
    const pathnames = location.pathname.split('/').filter((path) => path);
    if (pathnames.length > 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 2];
      setBreadcrumb(currentBreadcrumb);
    }
    if (pathnames.length === 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 1];
      setBreadcrumb(currentBreadcrumb);
    }
  }, [location]);

  useEffect(() => {
    let data = localStorage.getItem('user');
    let User = JSON.parse(data);
    setUserDet(User);
    const onlinecheck=localStorage.getItem('onlinestate')
   
    setOnlineTest(onlinecheck)
    localStorage.setItem('selectedYear','');
      
    localStorage.setItem('area',area);
  }, []);
  useEffect(() => {
    setLocationDet(userLocation || {});
  }, [userLocation]);
  useEffect(() => {
setPlantDet(userPlant)
  }, [userPlant]);

  const Indexeddbdatas = async () => {
    indexedDB.databases().then(databases => {
      const numberOfDatabases = databases.length;
    
      databases.forEach(db => {
    
      });
      if(numberOfDatabases>0){
        const request = indexedDB.open(databases[0].name); 
        request.onsuccess = async function(event) {
    const db=event.target.result
    const transaction = db.transaction(['trial', 'trialDetails', 'area', 'zone','image'], 'readonly');
const trialStore = transaction.objectStore('trial');
const trialDetailsStore = transaction.objectStore('trialDetails');
const areaStore = transaction.objectStore('area');
const zoneStore = transaction.objectStore('zone');
const imageStore=transaction.objectStore('image')

const TrialData = [];
const trialDetailsData = [];
const AreaData = [];
const zoneData = [];
const ImageData=[];


const trialCursorRequest = trialStore.openCursor();
trialCursorRequest.onsuccess = async function(event) {
  
const cursor = event.target.result;

if (cursor) {
  // setLoading(true);
TrialData.push(cursor.value);
cursor.continue();
} else {
const trialDetailsCursorRequest = trialDetailsStore.openCursor();
trialDetailsCursorRequest.onsuccess = async function(event) {
  const cursor = event.target.result;

  if (cursor) {
    trialDetailsData.push(cursor.value);
    cursor.continue();
  } else {
    const areaCursorRequest = areaStore.openCursor();
    areaCursorRequest.onsuccess = async function(event) {
      const cursor = event.target.result;

      if (cursor) {
        AreaData.push(cursor.value);
        cursor.continue();
      } else {

        const imageCursorRequest=imageStore.openCursor();
        imageCursorRequest.onsuccess=async function(event){
          const cursor=event.target.result;
          if(cursor){
            ImageData.push(cursor.value)
            cursor.continue();
          }
          else{
            const zoneCursorRequest = zoneStore.openCursor();
            zoneCursorRequest.onsuccess = async function(event) {
              const cursor = event.target.result;
            
              if (cursor) {
                zoneData.push(cursor.value);
                cursor.continue();
              } else {
                // Perform join operations based on specified conditions
                const joinedData = TrialData.map(trialItem => {
                  // console.log("Current trialItem:", trialItem);
                
                  // Look for matching trial details by trial_id
                  const matchingTrialDetails = trialDetailsData.find(trialDetailsItem => {
                    // console.log(`Comparing trialItem.id (${trialItem.id}) with trialDetailsItem.trial_id (${trialDetailsItem.trial_id})`);
                    return trialDetailsItem.trial_id === trialItem.id;
                  });
                
                  if (!matchingTrialDetails) {
                    console.warn(`No matching trialDetails found for trial id: ${trialItem.id}`);
                    return null;
                  }
                
                  // Fetch area, image, and zone if matching trial details are found
                  const matchingArea = AreaData.find(areaItem => areaItem.id === trialItem.area_id);
                  const matchingImage = matchingTrialDetails ? ImageData.find(imageItem => imageItem.id === matchingTrialDetails.img_id) : null;
                  const matchingZone = matchingTrialDetails ? zoneData.find(zoneItem => zoneItem.id === matchingTrialDetails.zone_id) : null;
                
                  // Only join if all matching data exists
                  if (!matchingArea || !matchingImage || !matchingZone) {
                    console.warn(`Incomplete data for trial id: ${trialItem.id}`);
                    return null;
                  }
                
                  return {
                    ...trialItem,
                    ...matchingTrialDetails,
                    area: matchingArea,
                    image: matchingImage,
                    imgdata: 'data:image/png;base64,' + arrayBufferToBase64(matchingImage.data),
                    zone: matchingZone
                  };
                })
                .filter(item => item !== null);  // Filter out any null entries (incomplete data)
                // console.log("offlineindex",joinedData)
                localStorage.setItem('lenofflinedata',joinedData.length)
                // setofflineInspectionlength(joinedData.length)
                if(joinedData.length>0){
                  let storedzoneArrays=JSON.parse(allzonedetails)
                  // zoneData.map(async (data, index) => {
                  //  console.log("zoneDATAAAA",zoneData)
                  //     console.log("zonetabledata11233",storedzoneArrays)
                  //     const selectedzone1 = storedzoneArrays.find((option) => option === data);
                  //     const selectedzone = storedzoneArrays.find(option => option.zone === data.zone && option.area_id === data.area_id);
                  //     console.log("selected zone81",selectedzone)
                  //     console.log("selected zone1",selectedzone1)
                  //     if(selectedzone===undefined){
                  //       const newZoneinsert={
                  //         area:data.area_id,
                  //         inputZone:data.zone
                  //       }
                  //       const response8=await fetchWithRetry(ApiURL + '/create_zone', newZoneinsert)
                  //       if(response8.status===200){
                  //         console.log("zonetabledata1123388",storedzoneArrays)
                  //         storedzoneArrays.push({ id:response8.data.identifiers[0].id})
                  //       }
                        


                  //     }
                   
                  // })
              
                    const file = new File([joinedData[0].image.data], joinedData[0].image.name, { type: joinedData[0].image.type });
                  
                    const imagesData =await generateImagesArray(file, joinedData[0].imgdata, joinedData[0],0,joinedData.length);
                   
                   
                  
                 
                }
                
//                 const dbName = 'nis'; // Replace 'YourDatabaseName' with the name of your database
// const deleteDBRequest =  window.indexedDB.deleteDatabase(dbName)
// console.log("deleteDBRequest",deleteDBRequest)
// deleteDBRequest.onsuccess = () => {
//   console.log(`Database "${dbName}" deleted successfully.`);
// };

// deleteDBRequest.onerror = (event) => {
//   console.error('Error deleting database:', event.target.error);
// };

              }
            };
          }
        }

      }
    };
  }
};
}
};

trialCursorRequest.onerror = function(event) {
console.error('Error fetching data from trial:', event.target.error);
}
  

};
request.onerror = function(event) {
  console.error('Error opening database:', event.target.error);
 
};
      }

    });
  
    
  };
  const generateImagesArray = async (file,imgdata,data,index,length) => {
    const imagesData = [];
  
    const reader = new FileReader();
    reader.onloadend = async () => {
      const imageType = file.type.split('/')[1];
      const imageBase64 = imgdata.split(',')[1];
      const imageName = `image_${Date.now()}.${imageType}`;
  
      imagesData.push({
        imageName,
        imageBase64,
        imageType,
      });
  
      await uploadBlobImagesAPI(imagesData,data,index,length);
     
     
    };
  
    reader.readAsDataURL(file,imgdata);
    
    // const convertedObject = {
    //   imageName: imagesData[0].imageName,
    //   imageBase64: imagesData[0].imageBase64,
    //   imageType: imagesData[0].imageType
    // };
    // console.log("convertedobject",convertedObject)
  
    
  };
  let zoneid=[];
  let trialid=[];
  let trialdetailsid=[];
 

const MAX_RETRIES = 10; // Set maximum retry attempts

const fetchWithRetry = async (url, options, retries = 0) => {
  try {
    const response = await axios.post(url, options,{
      headers: {
        Authorization: `Bearer ${access_token}`,
        
      }
    }
);
if (response.status === 200) {
  return response;
}
  } catch (error) {
    if (retries < MAX_RETRIES && error.response.status !== 200) {
      
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed
      return fetchWithRetry(url, options, retries + 1);
    } else {
      throw error; // Re-throw error if exceeding retries or status code is not the issue
    }
  }
};


const fetchWithRetryGet = async (url, retries = 0) => {
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
     
      }
    }
);
if (response.status === 200) {
  return response;
}
  } catch (error) {
    if (retries < MAX_RETRIES && error.response.status !== 200) {
      
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed
      return fetchWithRetryGet(url,retries + 1);
    } else {
      throw error; // Re-throw error if exceeding retries or status code is not the issue
    }
  }
};

const fetchzoneapi=async(url)=>{
  const response4=await axios.get(url,{
    headers: {
      Authorization: `Bearer ${access_token}`,
     
    }
  }
);
if (response4.status === 200) {
  return response4;
}
}

// const uploadBlobImagesAPI = async (images,data,index,length) => {
//   let fileData;
 
//   try {
//     console.log("imggggg",images);
//     const url = `${ApiURL}/upload_image_to_blob`; // Construct the full URL using ApiURL

//     const response1 = await axios.post(url, {
//       images,
//     },{
//       timeout: 180000, // 10 seconds timeout
//     });

//     console.log('imagesArray', response1.data);
//     console.log("imagearrayyy11111",response1.data[0].name)
   
//     if(response1.status===200){
//       const response2=await axios.post(ApiURL + `/create_imagepath/${response1.data[0].name}`,{
//         timeout: 180000, // 10 seconds timeout
//       })
//       if (response2.status===200){
//         const imgName=response2.data.identifiers[0].id;
//       let url = ApiURL +`/show_file_name/${imgName}`;
//       const response3=await axios.get(url,{
//         timeout: 180000, // 10 seconds timeout
//       });
//       if(response3.status===200){
//         fileData=response3.data[0].id;
//         console.log("data.zone.id",data.zone.id)
//         let url = ApiURL + `/show_zone`;
//         const response4=await axios.get(url,{
//           timeout: 180000, // 10 seconds timeout
//         });
//         if(response4.status===200){
//           const storedzoneArray = response4.data;
//           console.log("zonetabledata11",storedzoneArray)
//           const selectedzone1 = storedzoneArray.find((option) => option === data.zone);
//           const selectedzone = storedzoneArray.find(option => option.id === data.zone.id);
//           console.log("selected zone",selectedzone)
//           console.log("selected zone1",selectedzone1)
//           const newZone={
//             zone:data.zone.zone,
//             zone_id:data.zone.id,
//             area:data.area.id,
//           }
//           const newZoneinsert={
//             area:data.area.id,
//             inputZone:data.zone.zone
//           }
//           const newTrail=selectedzone?{
//     xrfNumber:data.xrfNumber,
//     Ni:data.Ni,
//     remarksValue:data.remarksValue,
//     comments:data.comments,
//     selectedDate:data.date,
//     fileData,
//     zone:data.zone.id,
//     area:data.area.id,
//     name:data.name,
//           }
//           :
//           {
//     xrfNumber:data.xrfNumber,
//     Ni:data.Ni,
//     remarksValue:data.remarksValue,
//     comments:data.comments,
//     selectedDate:data.date,
//     fileData,
//     area:data.area.id,
//     name:data.name,
//           }
//           if(selectedzone){
//             const response5=await axios.post(ApiURL + '/update_zone', newZone,{
//               timeout: 180000, // 10 seconds timeout
//             })
//             if (response5.status===200){
//             const response6=await axios.post(ApiURL + '/create_trial', newTrail,{
//               timeout: 180000, // 10 seconds timeout
//             })
//             if(response6.status===200){
//               newTrail.trialid=response6.data.identifiers[0].id;
//                 trialid.push(newTrail.trialid)           
//               const response7=await axios.post(ApiURL + '/create_trial_details', newTrail,{
//                 timeout: 180000, // 10 seconds timeout
//               })
//               if (response7.status===200){
//                 trialdetailsid.push(response7.data.identifiers[0].id)
//                 handleOpenAlert(`Inspection ${index} created Successfully`);
//                    if(length-1===index){
//                     setTimeout(() => {
//                       setLoading(false)
//                       // window.location.reload();
//                     }, 2500);
//               }
//               }
           
//             }
//             }
//           }
//           else{
//             const response8=await axios.post(ApiURL + '/create_zone', newZoneinsert,{
//               timeout: 180000, // 10 seconds timeout
//             })
//             if (response8.status===200){
//               newTrail.zone=response8.data.identifiers[0].id;
//               zoneid.push(newTrail.zone)
//               const response9=await axios.post(ApiURL + '/create_trial', newTrail,{
//                 timeout: 180000, // 10 seconds timeout
//               })
//               if(response9.status===200){
//                 newTrail.trialid=response9.data.identifiers[0].id;
//                 trialid.push(newTrail.trialid)
//                 const response10=await axios.post(ApiURL + '/create_trial_details', newTrail,{
//                   timeout: 180000, // 10 seconds timeout
//                 })
//                 if(response10.status===200){
//                   trialdetailsid.push(response10.data.identifiers[0].id)
//                   if(length-1===index){
//                     setTimeout(() => {
//                       setLoading(false)
//                       // window.location.reload();
//                     }, 2500);
                   
//                   }
//                 }
              
//               }
//             }
//           }
//         }
//       }
//       }
//   }

  
//     // Handle the response and update your UI or state as needed
//   } catch (error) {
//     console.error('Error uploading images:', error);
//     // Handle error, update UI, etc.
//   }
 
// };

  const uploadBlobImagesAPI = async (images,data,index,length) => {
    let fileData;
    try {
     
      const url = `${ApiURL}/upload_image_to_blob`; // Construct the full URL using ApiURL
  
      const response1 = await fetchWithRetry(url, {
        images,
      });
      if(response1.status===200){
        const response2=await fetchWithRetry(ApiURL + `/create_imagepath/${response1.data[0].name}`,{})
        if (response2.status===200){
          const imgName=response2.data.identifiers[0].id;
        let url = ApiURL +`/show_file_name/${imgName}`;
        const response3=await fetchWithRetryGet(url);
        if(response3.status===200){
          fileData=response3.data[0].id;
         
          let url = ApiURL + `/show_zone`;
          const response4=await fetchzoneapi(url);
          if(response4.status===200){
            const storedzoneArray = response4.data;
        
            const selectedzone1 = storedzoneArray.find((option) => option === data.zone);
            const selectedzone = storedzoneArray.find(option => option.zone === data.zone.zone && option.area_id === data.zone.area_id);
          
            const newZone={
              zone:data.zone.zone,
              zone_id:selectedzone?selectedzone.id:'',
              area:data.area.id,
            }
            const newZoneinsert={
              area:data.area.id,
              inputZone:data.zone.zone
            }
            const newTrail=selectedzone?{
      xrfNumber:data.xrfNumber,
      Ni:data.Ni,
      remarksValue:data.remarksValue,
      comments:data.comments,
      selectedDate:data.date,
      fileData,
      zone:selectedzone?selectedzone.id:'',
      area:data.area.id,
      name:data.name,
      id:data.tid
            }
            :
            {
      xrfNumber:data.xrfNumber,
      Ni:data.Ni,
      remarksValue:data.remarksValue,
      comments:data.comments,
      selectedDate:data.date,
      fileData,
      area:data.area.id,
      name:data.name,
      id:data.tid
            }
            if(fileData){
              if(selectedzone){
                const response5=await fetchWithRetry(ApiURL + '/update_zone', newZone)
                if (response5.status===200){
                const response6=await fetchWithRetry(ApiURL + '/create_trial', newTrail)
                if(response6.status===200 && response1.status===200){
  
                      newTrail.trialid=response6.data.identifiers[0].id;
                        trialid.push(newTrail.trialid)  
                        
                  const response7=await fetchWithRetry(ApiURL + '/create_trial_details_sync', newTrail)
                  // const arrayTrialDetailsId=JSON.parse(localStorage.getItem('trialdetailsidarray'))
                  // let trialdetailsIdvalues=arrayTrialDetailsId
                  //     trialdetailsIdvalues.push(response7.data.identifiers[0].id)
                  //     localStorage.setItem('trialdetailsidarray',JSON.stringify(trialdetailsIdvalues))
                  //   localStorage.setItem("trialsyncid",response7.data.identifiers[0].id)
                 
                  if (response7.status===200 && response1.status===200){
                    trialdetailsid.push(response7.data.identifiers[0].id)
                    indexedDB.databases().then(databases => {
                      const numberOfDatabases = databases.length;
                   
                      databases.forEach(db => {
                       
                      });
                      if(numberOfDatabases>0){
                        const request = indexedDB.open(databases[0].name); 
                        request.onsuccess = function(event) {
                    const db=event.target.result
                    const transactiontrial = db.transaction(trialName, 'readwrite');
                    const objectStoretrial= transactiontrial.objectStore(trialName);
                   
                 
                    const deleterequesttrial=objectStoretrial.delete(data.id)
                    deleterequesttrial.onsuccess = () => {
                     
                    };
                  
                    deleterequesttrial.onerror = () => {
                      console.error('Error updating record in IndexedDB');
                    };
                    const transactiontrialdetail = db.transaction(trialDetailsName, 'readwrite');
                    const objectStoretrialdetail = transactiontrialdetail.objectStore(trialDetailsName);
                  
                    const deleterequest=objectStoretrialdetail.delete(data.id)
                  
                    deleterequest.onsuccess = async() => {
                     
  
                     
                    };
                  
                    deleterequest.onerror = () => {
                      console.error('Error updating record in IndexedDB');
                    };
  
                };
                request.onerror = function(event) {
                  console.error('Error opening database:', event.target.error);
                 
                };
                      }
             
                    });             
                    setofflineInspectionlength(length)
                   
                    await Indexeddbdatas();
                    // handleOpenAlert(`Inspection ${index+1} created Successfully`);
                   
                  //      if(length-1===index){
                  //       setTimeout(() => {
                  //         setLoading(false)
                  //         localStorage.setItem("lenofflinedata",0)
                  //         window.location.reload();
                  //       }, 2500);
                  // }
                  }
                  if (response7.status===409){
                    trialdetailsid.push(data.id)
                    indexedDB.databases().then(databases => {
                      const numberOfDatabases = databases.length;
                   
                      databases.forEach(db => {
                       
                      });
                      if(numberOfDatabases>0){
                        const request = indexedDB.open(databases[0].name); 
                        request.onsuccess = function(event) {
                    const db=event.target.result
                    const transactiontrial = db.transaction(trialName, 'readwrite');
                    const objectStoretrial= transactiontrial.objectStore(trialName);
                   
                 
                    const deleterequesttrial=objectStoretrial.delete(data.id)
                    deleterequesttrial.onsuccess = () => {
                     
                    };
                  
                    deleterequesttrial.onerror = () => {
                      console.error('Error updating record in IndexedDB');
                    };
                    const transactiontrialdetail = db.transaction(trialDetailsName, 'readwrite');
                    const objectStoretrialdetail = transactiontrialdetail.objectStore(trialDetailsName);
                  
                    const deleterequest=objectStoretrialdetail.delete(data.id)
                  
                    deleterequest.onsuccess = async() => {
                     
  
                     
                    };
                  
                    deleterequest.onerror = () => {
                      console.error('Error updating record in IndexedDB');
                    };
  
                };
                request.onerror = function(event) {
                  console.error('Error opening database:', event.target.error);
                 
                };
                      }
             
                    });             
                    
                    setofflineInspectionlength(length)
                   
                    await Indexeddbdatas();
                    // handleOpenAlert(`Inspection ${index+1} created Successfully`);
                   
                  //      if(length-1===index){
                  //       setTimeout(() => {
                  //         setLoading(false)
                  //         localStorage.setItem("lenofflinedata",0)
                  //         window.location.reload();
                  //       }, 2500);
                  // }
                  }

               
                }
                }
              }
              else{
                  const response8=await fetchWithRetry(ApiURL + '/create_zone', newZoneinsert)
                  if (response8.status===200){
                    newTrail.zone=response8.data.identifiers[0].id;
                    zoneid.push(newTrail.zone)
                    const response9=await fetchWithRetry(ApiURL + '/create_trial', newTrail)
                    if(response9.status===200 && response1.status===200){
                
                                        newTrail.trialid=response9.data.identifiers[0].id;
                                        trialid.push(newTrail.trialid)
                                         
                      const response10=await fetchWithRetry(ApiURL + '/create_trial_details_sync', newTrail)
                      // const arrayTrialDetailsId=JSON.parse(localStorage.getItem('trialdetailsidarray'))
                      // let trialdetailsIdvalues=arrayTrialDetailsId
                      // trialdetailsIdvalues.push(response10.data.identifiers[0].id)
                      // localStorage.setItem('trialdetailsidarray',JSON.stringify(trialdetailsIdvalues))
                      //   localStorage.setItem("trialsyncid",response10.data.identifiers[0].id)
                     
                      if(response10.status===200 && response1.status===200){
                        trialdetailsid.push(response10.data.identifiers[0].id)
                        indexedDB.databases().then(databases => {
                          const numberOfDatabases = databases.length;
                        
                          databases.forEach(db => {
                           
                          });
                          if(numberOfDatabases>0){
                            const request = indexedDB.open(databases[0].name); 
                            request.onsuccess = function(event) {
                        const db=event.target.result
                        const transactiontrial = db.transaction(trialName, 'readwrite');
                        const objectStoretrial= transactiontrial.objectStore(trialName);
                       
                     
                        const deleterequesttrial=objectStoretrial.delete(data.id)
                        deleterequesttrial.onsuccess = () => {
                          
                        };
                      
                        deleterequesttrial.onerror = () => {
                          console.error('Error updating record in IndexedDB');
                        };
                        const transactiontrialdetail = db.transaction(trialDetailsName, 'readwrite');
                        const objectStoretrialdetail = transactiontrialdetail.objectStore(trialDetailsName);
                      
                        const deleterequest=objectStoretrialdetail.delete(data.id)
                      
                        deleterequest.onsuccess = async() => {
                        
                        
                        };
                      
                        deleterequest.onerror = () => {
                          console.error('Error updating record in IndexedDB');
                        };
      
                    };
                    request.onerror = function(event) {
                      console.error('Error opening database:', event.target.error);
                     
                    };
                          }
                 
                        });
                        localStorage.setItem('syncdatatrialid',response10.data.identifiers[0].id) 
                        // localStorage.setItem("trialsyncid",response10.data.identifiers[0].id)
                        setofflineInspectionlength(length)
                        await Indexeddbdatas();
                        // handleOpenAlert(`Inspection ${index+1} created Successfully`);
                      
                        // if(length-1===index){
                        //   setTimeout(() => {
                        //     setLoading(false)
                        //     localStorage.setItem("lenofflinedata",0)
                        //     window.location.reload();
                        //   }, 2500);
                         
                        // }
                      }
                      if(response10.status===409){
                        trialdetailsid.push(data.id)
                        indexedDB.databases().then(databases => {
                          const numberOfDatabases = databases.length;
                        
                          databases.forEach(db => {
                           
                          });
                          if(numberOfDatabases>0){
                            const request = indexedDB.open(databases[0].name); 
                            request.onsuccess = function(event) {
                        const db=event.target.result
                        const transactiontrial = db.transaction(trialName, 'readwrite');
                        const objectStoretrial= transactiontrial.objectStore(trialName);
                       
                     
                        const deleterequesttrial=objectStoretrial.delete(data.id)
                        deleterequesttrial.onsuccess = () => {
                          
                        };
                      
                        deleterequesttrial.onerror = () => {
                          console.error('Error updating record in IndexedDB');
                        };
                        const transactiontrialdetail = db.transaction(trialDetailsName, 'readwrite');
                        const objectStoretrialdetail = transactiontrialdetail.objectStore(trialDetailsName);
                      
                        const deleterequest=objectStoretrialdetail.delete(data.id)
                      
                        deleterequest.onsuccess = async() => {
                        
                        
                        };
                      
                        deleterequest.onerror = () => {
                          console.error('Error updating record in IndexedDB');
                        };
      
                    };
                    request.onerror = function(event) {
                      console.error('Error opening database:', event.target.error);
                     
                    };
                          }
                 
                        });
                  
                        setofflineInspectionlength(length)
                        await Indexeddbdatas();
                        // handleOpenAlert(`Inspection ${index+1} created Successfully`);
                      
                        // if(length-1===index){
                        //   setTimeout(() => {
                        //     setLoading(false)
                        //     localStorage.setItem("lenofflinedata",0)
                        //     window.location.reload();
                        //   }, 2500);
                         
                        // }
                      }
                    
                    }
                  }
                
               
              }
            }
        
          }
           
          
        }
        }
    }
 
    
      // Handle the response and update your UI or state as needed
    } catch (error) {
      console.error('Error uploading images:', error);
      // Handle error, update UI, etc.
    }
   

   
  };

  useEffect(() => {
    
    if(OnlineTest==='1'){
      // window.location.reload()
    // console.log("OnlineTest",OnlineTest)
      setTimeout(async () => {
        indexedDB.databases().then(databases => {
          const numberOfDatabases = databases.length;
        //  console.log("numberOfDatabases",databases)
        //  console.log("numberOfDatabases1",numberOfDatabases)
          databases.forEach(db => {
       
          });
          if(numberOfDatabases>0){
            const request = indexedDB.open(databases[0].name); 
            request.onsuccess = async function(event) {
              // console.log("request",request)
        const db=event.target.result
        const transaction = db.transaction(['trial', 'trialDetails', 'area', 'zone','image'], 'readonly');
    const trialStore = transaction.objectStore('trial');
    const trialDetailsStore = transaction.objectStore('trialDetails');
    const areaStore = transaction.objectStore('area');
    const zoneStore = transaction.objectStore('zone');
    const imageStore=transaction.objectStore('image')
    
    const TrialData = [];
    const trialDetailsData = [];
    const AreaData = [];
    const zoneData = [];
    const ImageData=[];
    
    
    const trialCursorRequest = trialStore.openCursor();
    trialCursorRequest.onsuccess = async function(event) {
      
    const cursor = event.target.result;
  
    if (cursor) {
      // setLoading(true);
    TrialData.push(cursor.value);
    cursor.continue();
    } else {
    const trialDetailsCursorRequest = trialDetailsStore.openCursor();
    trialDetailsCursorRequest.onsuccess = async function(event) {
      const cursor = event.target.result;
    
      if (cursor) {
        trialDetailsData.push(cursor.value);
        cursor.continue();
      } else {
        const areaCursorRequest = areaStore.openCursor();
        areaCursorRequest.onsuccess = async function(event) {
          const cursor = event.target.result;
     
          if (cursor) {
            AreaData.push(cursor.value);
            cursor.continue();
          } else {
    
            const imageCursorRequest=imageStore.openCursor();
            imageCursorRequest.onsuccess=async function(event){
              const cursor=event.target.result;
              if(cursor){
                ImageData.push(cursor.value)
                cursor.continue();
              }
              else{
                const zoneCursorRequest = zoneStore.openCursor();
                zoneCursorRequest.onsuccess = async function(event) {
                  const cursor = event.target.result;
                 
                  if (cursor) {
                    zoneData.push(cursor.value);
                    cursor.continue();
                  } else {
                    // Perform join operations based on specified conditions
                    // console.log("TrialData",TrialData)
                    // console.log("AreaData",AreaData)
                    // console.log("ImageData",ImageData)
                    // console.log("zoneData",zoneData)
                    const joinedData = TrialData.map(trialItem => {
                      // console.log("Current trialItem:", trialItem);
                    
                      // Look for matching trial details by trial_id
                      const matchingTrialDetails = trialDetailsData.find(trialDetailsItem => {
                        // console.log(`Comparing trialItem.id (${trialItem.id}) with trialDetailsItem.trial_id (${trialDetailsItem.trial_id})`);
                        return trialDetailsItem.trial_id === trialItem.id;
                      });
                    
                      if (!matchingTrialDetails) {
                        console.warn(`No matching trialDetails found for trial id: ${trialItem.id}`);
                        return null;
                      }
                    
                      // Fetch area, image, and zone if matching trial details are found
                      const matchingArea = AreaData.find(areaItem => areaItem.id === trialItem.area_id);
                      const matchingImage = matchingTrialDetails ? ImageData.find(imageItem => imageItem.id === matchingTrialDetails.img_id) : null;
                      const matchingZone = matchingTrialDetails ? zoneData.find(zoneItem => zoneItem.id === matchingTrialDetails.zone_id) : null;
                    
                      // Only join if all matching data exists
                      if (!matchingArea || !matchingImage || !matchingZone) {
                        console.warn(`Incomplete data for trial id: ${trialItem.id}`);
                        return null;
                      }
                    
                      return {
                        ...trialItem,
                        ...matchingTrialDetails,
                        area: matchingArea,
                        image: matchingImage,
                        imgdata: 'data:image/png;base64,' + arrayBufferToBase64(matchingImage.data),
                        zone: matchingZone
                      };
                    })
                    .filter(item => item !== null);  // Filter out any null entries (incomplete data)
                    
                    // console.log("offlinetest", joinedData);
                    
                    localStorage.setItem('lenofflinedata',joinedData.length)
                    // setofflineInspectionlength(joinedData.length)
                    if(joinedData.length>0){
                      // let storedzoneArrays=JSON.parse(allzonedetails)
                      // zoneData.map(async (data, index) => {
                      //  console.log("zoneDATAAAA",zoneData)
                      //     console.log("zonetabledata11233",storedzoneArrays)
                      //     const selectedzone1 = storedzoneArrays.find((option) => option === data);
                      //     const selectedzone = storedzoneArrays.find(option => option.zone === data.zone && option.area_id === data.area_id);
                      //     console.log("selected zone81",selectedzone)
                      //     console.log("selected zone1",selectedzone1)
                      //     if(selectedzone===undefined){
                      //       const newZoneinsert={
                      //         area:data.area_id,
                      //         inputZone:data.zone
                      //       }
                      //       const response8=await fetchWithRetry(ApiURL + '/create_zone', newZoneinsert)
                      //       if(response8.status===200){
                      //         console.log("zonetabledata1123388",storedzoneArrays)
                      //         storedzoneArrays.push({ id:response8.data.identifiers[0].id})
                      //       }
                            


                      //     }
                       
                      // })

                     
                        const file = new File([joinedData[0].image.data], joinedData[0].image.name, { type: joinedData[0].image.type });
                       
                        const imagesData =await generateImagesArray(file, joinedData[0].imgdata, joinedData[0],0,joinedData.length);
                       
                       
                      
                     
                    }
                    
    //                 const dbName = 'nis'; // Replace 'YourDatabaseName' with the name of your database
    // const deleteDBRequest =  window.indexedDB.deleteDatabase(dbName)
    // console.log("deleteDBRequest",deleteDBRequest)
    // deleteDBRequest.onsuccess = () => {
    //   console.log(`Database "${dbName}" deleted successfully.`);
    // };
    
    // deleteDBRequest.onerror = (event) => {
    //   console.error('Error deleting database:', event.target.error);
    // };
    
                  }
                };
              }
            }
    
          }
        };
      }
    };
    }
    };
    
    trialCursorRequest.onerror = function(event) {
    console.error('Error fetching data from trial:', event.target.error);
    }
      
    
    };
    request.onerror = function(event) {
      console.error('Error opening database:', event.target.error);
     
    };
          }
 
        });




    


      
      }, 0);


   
    }

    
      }, [isOnline,OnlineTest]);

      useEffect(() => {
        const fetchDataAndProcess = async () => {
          // console.log("offlendata",localStorage.getItem('lenofflinedata'),localStorage.getItem('onlinestate'))
          if (localStorage.getItem('lenofflinedata') === '0' && localStorage.getItem('onlinestate') === '1') {
            // console.log("hello")
            const dbName = 'nis'; // Replace 'YourDatabaseName' with the name of your database
            const deleteDBRequest = window.indexedDB.deleteDatabase(dbName);
           
      
            deleteDBRequest.onsuccess = () => {
            // console.log("hello1")
            };
      
            deleteDBRequest.onerror = (event) => {
              console.error('Error deleting database:', event.target.error);
            };
      
            deleteDBRequest.onblocked = (event) => {
              console.error('Database deletion is blocked:', event.target.error);
            };
          }
        };
      
        fetchDataAndProcess();
      }, [isOnline, OnlineTest]);
      
    useEffect(()=>{
     
setofflineInspectionlength(localStorage.getItem('lenofflinedata') || '0')
    },[offlineInspectionlength])
  

      // const CircularProgressWithLabel = (props) => {
      //   return (
      //     <div style={{ position: 'relative', display: props.hide ? 'none' : 'block' }}>
      //       <CircularProgress variant="determinate" {...props} />
      //       <Typography
      //         variant="caption"
      //         component="div"
      //         color="textSecondary"
      //         style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      //       >
      //         {`${props.value}%`}
      //       </Typography>
      //     </div>
      //   );
      // };
      


  function getTitleColor(niValue) {
    if (niValue > 1) {
      return 'title-red';
    } else if (niValue >= 0.1 && niValue <= 1) {
      return 'title-orange';
    } else if (niValue >= 0.05 && niValue <= 0.1) {
      return 'title-blue';
    } else {
      return 'title-green';
    }
  }

  function formatDate(inputDate) {
    // Split the input date by the 'T' character
    const parts = inputDate.split('T');
    const currentDate = new Date(parts); // Parse the date string
    
    // Increase the date by one day
    currentDate.setDate(currentDate.getDate() + 1);
  
    // Split the date part into year, month, and day
    const dateParts = currentDate.toISOString().split('T')[0];
   
    const finaldateparts=dateParts.split('-');
    const year = finaldateparts[0];
    const month = finaldateparts[1];
    const day = finaldateparts[2];

    // Create the formatted date string in "dd-mm-yyyy" format
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const handleOpenMenu = (event, data, index) => {
    const newMenuAnchors = [...menuAnchors];
    newMenuAnchors[index] = event.currentTarget;
    setMenuAnchors(newMenuAnchors);
  };

  // Function to handle closing the menu for a specific card
  const handleCloseMenu = (index) => {
    const newMenuAnchors = [...menuAnchors];
    newMenuAnchors[index] = null;
    setMenuAnchors(newMenuAnchors);
  };
  const handleCheckboxChange = () => {
    setShowHistory(!showHistory);
  };

  const ApiURL = configParam.API_URL;
  const modalInputLabelStyle = {

    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
  };
  useEffect(() => {
    let data = localStorage.getItem("user");
    let User = JSON.parse(data)
    setUserDet(User);
  }, []);
  useEffect(() => {
    if (userDet) {
      setIconsVisibleTrial(userDet.role_id === 3 || userDet.role_id === 1);
    }
  }, [userDet]);

  

  const [selectedYear, setSelectedYear] = useState('');
 
 
 
  const handleYearChange = (event) => {
   
    
  
    const selectedYear = event.target.value;

    localStorage.setItem('selectedYear',selectedYear);

    setSelectedYear(selectedYear);
  
    // Trigger API call when year changes
  //   if (area !== null && selectedYear !== '' && selectedYear !== 'All Year') {
  //     getTrialAreaDateDetails(area, selectedYear);
  //     getTrialYearDetails(selectedYear);
  //     // getTrialTableAreaDetails(area);
  //   }
  //  if(area !== null && selectedYear !== '' && selectedYear === 'All Year'){
  //   getTrialAreaDateDetails(area, selectedYear);
  //  }
    // setSelectedYear(event.target.value);
  };

  const [yearOptions, setYearsOptions] = useState([]);
  const resetFormFields = () => {
    setArea(null);
    setDescription('');
    setZone('');
    setXrfNumber('');
    setNi('');
    setComments('');
    setRemarksValue('');
    setSelectedDate(null);
    // Reset any other state variables as needed
  };

  const handleOpen = () => {
    if(isOnline){
    setOpen(true);
    setExpanded([]);
    }
    else{
      setOpenOffline(true);
    }
  };



  const handleClose = () => {
    if(isOnline){
      setSelectedRowData(null);
      setOpen(false);
      setCurrentTrialData([]);
    }
    else{
      setSelectedRowData(null);
    setOpenOffline(false);
    setCurrentTrialData([]);
    }
    
    // setExpanded([]);
    // resetFormFields();
  };
const getTrialOfflinedetails=()=>{
    const transaction = db.transaction(['trial', 'trialDetails', 'area', 'zone','image'], 'readonly');
const trialStore = transaction.objectStore('trial');
const trialDetailsStore = transaction.objectStore('trialDetails');
const areaStore = transaction.objectStore('area');
const zoneStore = transaction.objectStore('zone');
const imageStore=transaction.objectStore('image')

const trialdata = [];
const trialDetailsData = [];
const areaData = [];
const zoneData = [];
const imageData=[];


const trialCursorRequest = trialStore.openCursor();
trialCursorRequest.onsuccess = function(event) {
const cursor = event.target.result;

if (cursor) {
trialdata.push(cursor.value);
cursor.continue();
} else {
const trialDetailsCursorRequest = trialDetailsStore.openCursor();
trialDetailsCursorRequest.onsuccess = function(event) {
  const cursor = event.target.result;

  if (cursor) {
    trialDetailsData.push(cursor.value);
    cursor.continue();
  } else {
    const areaCursorRequest = areaStore.openCursor();
    areaCursorRequest.onsuccess = function(event) {
      const cursor = event.target.result;

      if (cursor) {
        areaData.push(cursor.value);
        cursor.continue();
      } else {

        const imageCursorRequest=imageStore.openCursor();
        imageCursorRequest.onsuccess=function(event){
          const cursor=event.target.result;
          if(cursor){
            imageData.push(cursor.value)
            cursor.continue(); 
          }
          else{
            const zoneCursorRequest = zoneStore.openCursor();
            zoneCursorRequest.onsuccess = function(event) {
              const cursor = event.target.result;

              if (cursor) {
                zoneData.push(cursor.value);
                cursor.continue();
              } else {
                // Perform join operations based on specified conditions
                const filteredareadata=areaData.filter(item => item.plant_id === userlogoutdata);
               
                const joinedData = trialdata.map(trialItem => {
                  // console.log("Current trialItem:", trialItem);
            
                  // Look for matching trial details by trial_id
                  const matchingTrialDetails = trialDetailsData.find(trialDetailsItem => {
                    // console.log(`Comparing trialItem.id (${trialItem.id}) with trialDetailsItem.trial_id (${trialDetailsItem.trial_id})`);
                    return trialDetailsItem.trial_id === trialItem.id;
                  });
               
                
                  if (!matchingTrialDetails) {
                    console.warn(`No matching trialDetails found for trial id: ${trialItem.id}`);
                    return null;
                  }
                
                  // Fetch area, image, and zone if matching trial details are found
                  const matchingArea = areaData.find(areaItem => areaItem.id === trialItem.area_id);
                  const matchingImage = matchingTrialDetails ? imageData.find(imageItem => imageItem.id === matchingTrialDetails.img_id) : null;
                  const matchingZone = matchingTrialDetails ? zoneData.find(zoneItem => zoneItem.id === matchingTrialDetails.zone_id) : null;
                
                  // Only join if all matching data exists
                  if (!matchingArea || !matchingImage || !matchingZone) {
                    console.warn(`Incomplete data for trial id: ${trialItem.id}`);
                    return null;
                  }
                
                  return {
                    ...trialItem,
                    ...matchingTrialDetails,
                    area: matchingArea,
                    image: matchingImage,
                    imgdata: 'data:image/png;base64,' + arrayBufferToBase64(matchingImage.data),
                    zone: matchingZone
                  };
                })
                .filter(item => item !== null);  // Filter out any null entries (incomplete data)
               
                setJoined((prevData) => {
                  const hasDuplicateIds = (dataArray) => {
                    const idSet = new Set();
                    for (const item of dataArray) {
                      if (idSet.has(item.id)) {
                        return true; // Duplicate found
                      }
                      idSet.add(item.id);
                    }
                    return false;
                  };
              
                  // Update only if there are no duplicate IDs in the combined data
                  return hasDuplicateIds(joinedData) ? prevData : joinedData;
                });
             
                const joinedtrialData = trialdata.map(trialItem => {
                  const matchingArea = areaData.find(
                    areaItem => areaItem.id === trialItem.area_id
                  );
                  if (!matchingArea) {
                    return null;
                  }
                  return {
                    ...trialItem,
                    area: matchingArea,
                  };
                })
                .filter(item => item !== null);  // Filter out any null entries (incomplete data)
                setTrialTableData(joinedtrialData)
         
              }
            };
          }
        }

      }
    };
  }
};
}
};

trialCursorRequest.onerror = function(event) {
console.error('Error fetching data from trial:', event.target.error);
}
  

}

  useEffect(() => {
  if(!isOnline && db){
  
      const transaction = db.transaction(['trial', 'trialDetails', 'area', 'zone','image'], 'readonly');
const trialStore = transaction.objectStore('trial');
const trialDetailsStore = transaction.objectStore('trialDetails');
const areaStore = transaction.objectStore('area');
const zoneStore = transaction.objectStore('zone');
const imageStore=transaction.objectStore('image')

const trialdata = [];
const trialDetailsData = [];
const areaData = [];
const zoneData = [];
const imageData=[];


const trialCursorRequest = trialStore.openCursor();
trialCursorRequest.onsuccess = function(event) {
const cursor = event.target.result;

if (cursor) {
  trialdata.push(cursor.value);
  // console.log("trialdata34",trialdata)
  cursor.continue();
} else {
  const trialDetailsCursorRequest = trialDetailsStore.openCursor();
  trialDetailsCursorRequest.onsuccess = function(event) {
    const cursor = event.target.result;

    if (cursor) {
      trialDetailsData.push(cursor.value);
      cursor.continue();
    } else {
      const areaCursorRequest = areaStore.openCursor();
      areaCursorRequest.onsuccess = function(event) {
        const cursor = event.target.result;

        if (cursor) {
          areaData.push(cursor.value);
          cursor.continue();
        } else {

          const imageCursorRequest=imageStore.openCursor();
          imageCursorRequest.onsuccess=function(event){
            const cursor=event.target.result;
            if(cursor){
              imageData.push(cursor.value)
              cursor.continue();
            }
            else{
              const zoneCursorRequest = zoneStore.openCursor();
              zoneCursorRequest.onsuccess = function(event) {
                const cursor = event.target.result;
  
                if (cursor) {
                  zoneData.push(cursor.value);
                  cursor.continue();
                } else {
                  // Perform join operations based on specified conditions
                  const filteredareadata=areaData.filter(item => item.plant_id === userlogoutdata);
              
                 if (!trialdata || !Array.isArray(trialdata) || trialdata.length === 0) {
                  console.warn("trialData is either undefined, not an array, or empty.");
                } 
                  // console.log("trialData is defined and has data:", trialdata);
                  
                  const joinedData = trialdata.map(trialItem => {
                    // console.log("Current trialItem:", trialItem);
                    
                    const matchingTrialDetails = trialDetailsData.find(trialDetailsItem => {
                      // console.log(`Comparing trialItem.id (${trialItem.id}) with trialDetailsItem.trial_id (${trialDetailsItem.trial_id})`);
                      return trialDetailsItem.trial_id === trialItem.id;
                    });
                
                    if (!matchingTrialDetails) {
                      console.warn(`No matching trialDetails found for trial id: ${trialItem.id}`);
                      return null;
                    }
                
                    const matchingArea = areaData.find(areaItem => areaItem.id === trialItem.area_id);
                    const matchingImage = matchingTrialDetails ? imageData.find(imageItem => imageItem.id === matchingTrialDetails.img_id) : null;
                    const matchingZone = matchingTrialDetails ? zoneData.find(zoneItem => zoneItem.id === matchingTrialDetails.zone_id) : null;
                
                    if (!matchingArea || !matchingImage || !matchingZone) {
                      console.warn(`Incomplete data for trial id: ${trialItem.id}`);
                      return null;
                    }
                
                    return {
                      ...trialItem,
                      ...matchingTrialDetails,
                      area: matchingArea,
                      image: matchingImage,
                      imgdata: 'data:image/png;base64,' + arrayBufferToBase64(matchingImage.data),
                      zone: matchingZone
                    };
                  })
                  .filter(item => item !== null);
                
                
                  setJoined((prevData) => {
                    const hasDuplicateIds = (dataArray) => {
                      const idSet = new Set();
                      for (const item of dataArray) {
                        if (idSet.has(item.id)) {
                          return true; // Duplicate found
                        }
                        idSet.add(item.id);
                      }
                      return false;
                    };
                
                    // Update only if there are no duplicate IDs in the combined data
                    return hasDuplicateIds(joinedData) ? prevData : joinedData;
                  });
                 
                  const joinedtrialData = trialdata.map(trialItem => {
                    const matchingArea = areaData.find(
                      areaItem => areaItem.id === trialItem.area_id
                    );
                    if (!matchingArea) {
                      return null;
                    }
                    return {
                      ...trialItem,
                      area: matchingArea,
                    };
                  })
                  .filter(item => item !== null);  // Filter out any null entries (incomplete data)
                  setTrialTableData(joinedtrialData)
                  
                }
              };
            }
          }

        }
      };
    }
  };
}
};

trialCursorRequest.onerror = function(event) {
console.error('Error fetching data from trial:', event.target.error);
}
    
  }
}, [db]); 

useEffect(()=>{
if(!isOnline && db){
  const transaction = db.transaction(['trial', 'trialDetails', 'area', 'zone','image'], 'readonly');
  const zoneStore = transaction.objectStore('zone');
  const zoneData = [];
  const zoneCursorRequest = zoneStore.openCursor();
  zoneCursorRequest.onsuccess = function(event) {
    const cursor = event.target.result;

    if (cursor) {
      zoneData.push(cursor.value);
      cursor.continue();
    }
  }
  zoneCursorRequest.onerror = function(event) {
    console.error('Error fetching data from zone:', event.target.error);
    }
    const dataString = JSON.stringify(zoneData);
    localStorage.setItem('allzoneDataoffline', dataString);
}
},[db])
    
    
    useEffect(() => {
      if(joined){
      
        const filteredArray = joined.filter(item => item.area.plant_id === userlogoutdata);
   
     
        setTrialOfflineData(filteredArray);
      
      }
        
    }, [joined]);   
  
  const handleAreaChange = (event) => {
   
  
    const selectedArea = event.target.value;
    
    localStorage.setItem('area',selectedArea);
  setArea(selectedArea);
  

  // Trigger API call when area changes
  // if (selectedYear !== '' && selectedArea !== '12') {
  //   getTrialAreaDateDetails(selectedArea, selectedYear);
  //   getTrialTableAreaDetails(selectedArea);
  // }
//   if (selectedYear !== '') {
//     // Call the appropriate API based on the area selected
//     if (selectedArea === '12') {
//         // Call the plant details API
//         getTrialPlantDateDetails(userlogoutdata, selectedYear);
//         getTrialTablePlantDetails(userlogoutdata);
//     } else {
//         // Call the area details API
//         getTrialAreaDateDetails(selectedArea, selectedYear);
        
//         // getTrialTableAreaDetails(selectedArea);
//     }
// }
    // setArea(event.target.value);
  };
 

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setSelectedImage(null);
  };

  const handleCloseViewIconModal = () => {
    setOpenViewIconModal(false);
    setShowHistory(false)
  };

  const handleOpenAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };
  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const handleImageIconClick = (params) => {
    if(isOnline){
      const afgpInnerRows = groupedRows[expandedRowData.area]?.innerRows;
          // Retrieve the innerRow at index 3
          const innerRowAtIndex = afgpInnerRows[params.id - 1];
          if (params && params.id) {
            const element = trialData.find((item) => item.id === innerRowAtIndex.id);
            const trialId = element.id;
            let url = ApiURL + `/get_current_trial_details/${trialId}`;
      
            axios.get(url,{
              headers: {
                Authorization: `Bearer ${access_token}`,
              
              }
            }
      )
              .then((response) => {
                const currentTrial = response.data;
                const parts = currentTrial[0].path.split('/'); // Split the string by the backslash character
                const valuesAfterSlash = parts[1]
                const imageUrl = ApiURL + `/show_images`;
                axios.post(imageUrl,{
                  imageName: valuesAfterSlash,
                 
                },{
                  headers: {
                    Authorization: `Bearer ${access_token}`,
                  
                  }
                }
          
              )
                .then((response)=>{
                  const fileExtension = valuesAfterSlash.split('.').pop();
                  const updatedresponse=`data:image/${fileExtension};base64,`+response.data;
                  setSelectedImage(updatedresponse);

                
                setOpenImageModal(true);
                })
                
                // const imageUrl = ApiURL + `/show_image/${valuesAfterSlash}`;
            
               
      
              })
              .catch((error) => {
              });
      
          }
    }
    else{
      const afgpInnerRows = groupedRows[expandedRowData.area]?.innerRows;
  
          const innerRowAtIndex = afgpInnerRows[params.id - 1];
         
          const base64String = arrayBufferToBase64(innerRowAtIndex.image.data)
          const updatedbase64String='data:image/png;base64,' + base64String
          
          // const blob = new Blob([innerRowAtIndex.image], { type: 'image/png' }); // Assuming data is an image
          // const file = new File([blob],innerRowAtIndex.image.name, { type: 'image/png' });
          // console.log("files",file)
          // displayImage(file)
          setSelectedImage(updatedbase64String);
          setOpenImageModal(true);
          // const imageUrl = URL.createObjectURL(file); // Convert ArrayBuffer to an image URL
          // console.log("urll",imageUrl)
    }


  };

  const handleView = (data, index) => {
    if(isOnline){
 // Now you have access to the data and index of the clicked item.
 setLoading(true)
 let urlzone = ApiURL + `/show_trial_details_zone/${data.zone_id}`
 const parts = data.path.split('/'); // Split the string by the backslash character
 const valuesAfterSlash = parts[1]
 const imageUrl = ApiURL + `/show_images`;
 axios.post(imageUrl,{
   imageName: valuesAfterSlash,
  
 },{
  headers: {
    Authorization: `Bearer ${access_token}`,
   
  }
}

)
 .then((response)=>{
   const fileExtension = valuesAfterSlash.split('.').pop();
   const updatedresponse=`data:image/${fileExtension};base64,`+response.data;
   setCurrentViewData([data]);
   setSelectedImage(updatedresponse);
  
 
 
 })
//  const imageUrl = ApiURL + `/show_image/${encodeURIComponent(valuesAfterSlash)}`;
//  setCurrentViewData([data]);
//  setSelectedImage(imageUrl); // Set the selected image URL
 axios.get(urlzone,{
  headers: {
    Authorization: `Bearer ${access_token}`,
   
  }
}
)
   .then((response) => {
    const currentTrial = response.data;
    const fetchImages = async (currentTrial) => {
      const imgarray = [];
      
      try {
        const imageFetchPromises = currentTrial.map((trial) => {
          const parts = trial.path.split('/'); // Split the string by the backslash character
          const valuesAfterSlash = parts[1];
          const imageUrl = ApiURL + `/show_images`;
    
          return axios.post(imageUrl, {
            imageName: valuesAfterSlash,
          }, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              
            }
          }).then((response) => {
            const fileExtension = valuesAfterSlash.split('.').pop();
            const updatedresponse = `data:image/${fileExtension};base64,` + response.data;
            imgarray.push(updatedresponse);
          });
        });
    
        await Promise.all(imageFetchPromises);
    
      
        return imgarray;
      } catch (error) {
        console.error("Error fetching images:", error);
        return imgarray; // Return empty array or handle as needed
      }
    };
    
    // Example usage
    fetchImages(currentTrial).then((imgarray) => {
  
      if(imgarray.length===currentTrial.length){
        setCurrentHistoryImageData(imgarray)
        setCurrentHistoryViewData(currentTrial); // Set the currentTrial state
        setLoading(false)
      }
    });
   })
   .catch((error) => {
     // Handle error
   });
 setOpenViewIconModal(true);



 // You can perform any other actions you need with the data here.
    }else{
     
     
          const base64String = arrayBufferToBase64(data.image.data)
          const updatedbase64String='data:image/png;base64,' + base64String
       
          // const blob = new Blob([innerRowAtIndex.image], { type: 'image/png' }); // Assuming data is an image
          // const file = new File([blob],innerRowAtIndex.image.name, { type: 'image/png' });
          // console.log("files",file)
          // displayImage(file)
          setSelectedImage(updatedbase64String);
          setOpenImageModal(true);
    }
   
  };

  const handleViewIconClick = (params) => {
    setLoading(true)
    const afgpInnerRows = groupedRows[expandedRowData.area]?.innerRows;

    // Retrieve the innerRow at index 3
    const innerRowAtIndex = afgpInnerRows[params.id - 1];
    if (params && params.id) {
      const element = trialData.find((item) => item.id === innerRowAtIndex.id);
      const trialId = element.id;
      const trialzone = element.zone_id;
      let url = ApiURL + `/get_current_trial_details/${trialId}`;
      let urlzone = ApiURL + `/show_trial_details_zone/${trialzone}`
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
)
        .then((response) => {
          const currentTrial = response.data;
          const parts = currentTrial[0].path.split('/'); // Split the string by the backslash character
          const valuesAfterSlash = parts[1]
          const imageUrl = ApiURL + `/show_images`;
          axios.post(imageUrl,{
            imageName: valuesAfterSlash,
           
          },{
            headers: {
              Authorization: `Bearer ${access_token}`,
              
            }
          }
    
         )
          .then((response)=>{
            const fileExtension = valuesAfterSlash.split('.').pop();
            const updatedresponse=`data:image/${fileExtension};base64,`+response.data;
            setCurrentViewData(currentTrial);
            setSelectedImage(updatedresponse);
           
          
          
          })

          axios.get(urlzone,{
            headers: {
              Authorization: `Bearer ${access_token}`,
             
            }
          }
    )
            .then((response) => {
              const currentTrial = response.data;
              const fetchImages = async (currentTrial) => {
                const imgarray = [];
                
                try {
                  const imageFetchPromises = currentTrial.map((trial) => {
                    const parts = trial.path.split('/'); // Split the string by the backslash character
                    const valuesAfterSlash = parts[1];
                    const imageUrl = ApiURL + `/show_images`;
              
                    return axios.post(imageUrl, {
                      imageName: valuesAfterSlash,
                    }, {
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                        
                      }
                    }).then((response) => {
                      const fileExtension = valuesAfterSlash.split('.').pop();
                      const updatedresponse = `data:image/${fileExtension};base64,` + response.data;
                      imgarray.push(updatedresponse);
                    });
                  });
              
                  await Promise.all(imageFetchPromises);
              
                
                  return imgarray;
                } catch (error) {
                  console.error("Error fetching images:", error);
                  return imgarray; // Return empty array or handle as needed
                }
              };
              
              // Example usage
              fetchImages(currentTrial).then((imgarray) => {
            
                if(imgarray.length===currentTrial.length){
                  setCurrentHistoryImageData(imgarray)
                  setCurrentHistoryViewData(currentTrial); // Set the currentTrial state
                  setLoading(false)
                }
              });
       
             
            })
            .catch((error) => {
              // Handle error
            });
          setOpenViewIconModal(true);

        })
        .catch((error) => {
        });

    }


  };
//   useEffect(()=>{
//     const imgarray=[];
//     currentHistoryViewData.map((trial, index) => {
//       const parts = trial.path.split('/'); // Split the string by the backslash character
//       const valuesAfterSlash = parts[1];
//       const imageUrl = ApiURL + `/show_images`;
     
//       axios.post(imageUrl,{
//         imageName: valuesAfterSlash,
       
//       }
//     )
//       .then((response)=>{
//         const fileExtension = valuesAfterSlash.split('.').pop();
//         const updatedresponse=`data:image/${fileExtension};base64,`+response.data;
//         imgarray.push(updatedresponse)
//       })
//     })
//     console.log("imgggarrr",imgarray)
//     console.log("imgggarrr1",imgarray[0])
// setCurrentHistoryImageData(imgarray)
//   },[currentHistoryViewData])
  const handleConfirmMobileDelete = () => {
    if(isOnline){
      const trialId = currentTrialDelete.id;
      let url = ApiURL + `/get_current_trial_details/${trialId}`;

      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
)
        .then((response) => {
          const currentTrial = response.data;
          const parts = currentTrial[0].path.split('/'); // Split the string by the backslash character
          const valuesAfterSlash = parts[1]
          const element = currentTrial[0];
          const trialId = element.id;
         
          deleteTrial(trialId);
        })
        .catch((error) => {
        });

    }else{
     
     
      const transactiontrial = db.transaction(trialName, 'readwrite');
      const objectStoretrial= transactiontrial.objectStore(trialName);
    
      const deleterequesttrial=objectStoretrial.delete(currentTrialDelete.id)
      deleterequesttrial.onsuccess = () => {
        
      };
    
      deleterequesttrial.onerror = () => {
        console.error('Error updating record in IndexedDB');
      };
      const transactiontrialdetail = db.transaction(trialDetailsName, 'readwrite');
      const objectStoretrialdetail = transactiontrialdetail.objectStore(trialDetailsName);
    
      const deleterequest=objectStoretrialdetail.delete(currentTrialDelete.id)
    
      deleterequest.onsuccess = () => {
       
              setAlertOpen(true);
              setAlertMessage('inspection deleted successfully')
              getTrialOfflinedetails();
              setConfirmationModalOpen(false);
              setConfirmationMobileModalOpen(false)
              setSelectedItem(null);
      };
    
      deleterequest.onerror = () => {
        console.error('Error updating record in IndexedDB');
      };
    }
      
      
    

  };



     
  const handleConfirmDelete = () => {
   
    if(isOnline){

    if(groupedRows&& groupedRows[expandedRowData.area]){
      
      const afgpInnerRows = groupedRows[expandedRowData.area]?.innerRows;
     

      // Retrieve the innerRow at index 3
      const innerRowAtIndex = afgpInnerRows[selectedItem - 1];
  
     
      if (selectedItem && innerRowAtIndex && innerRowAtIndex.id) {

        const element = trialData.find((item) => item.id === innerRowAtIndex.id);
        const trialId = element.id;
        let url = ApiURL + `/get_current_trial_details/${trialId}`;
  
        axios.get(url,{
          headers: {
            Authorization: `Bearer ${access_token}`,
        
          }
        }
  )
          .then((response) => {
            const currentTrial = response.data;
            const parts = currentTrial[0].path.split('/'); // Split the string by the backslash character
            const valuesAfterSlash = parts[1]
            const element = currentTrial[0];
            const trialId = element.id;
            // deleteTrialImage(valuesAfterSlash);
            deleteTrial(trialId);
           
          })
          .catch((error) => {
          });
          
      }
      else {
        
        // console.error('selectedItem, innerRowAtIndex, or innerRowAtIndex.id is null or undefined');
    }
    }
    else {
      console.error('selectedItem, innerRowAtIndex, or innerRowAtIndex.id is null or undefined1234');
  }
}
else{
  const afgpInnerRows = groupedRows[expandedRowData.area]?.innerRows;

  // Retrieve the innerRow at index 3
  const innerRowAtIndex = afgpInnerRows[selectedItem - 1];
 
  const transactiontrial = db.transaction(trialName, 'readwrite');
  const objectStoretrial= transactiontrial.objectStore(trialName);

  const deleterequesttrial=objectStoretrial.delete(innerRowAtIndex.id)
  deleterequesttrial.onsuccess = () => {
    
  };

  deleterequesttrial.onerror = () => {
    console.error('Error updating record in IndexedDB');
  };
  const transactiontrialdetail = db.transaction(trialDetailsName, 'readwrite');
  const objectStoretrialdetail = transactiontrialdetail.objectStore(trialDetailsName);

  const deleterequest=objectStoretrialdetail.delete(innerRowAtIndex.id)

  deleterequest.onsuccess = () => {
   
          setAlertOpen(true);
          setAlertMessage('inspection deleted successfully')
          getTrialOfflinedetails();
          setConfirmationModalOpen(false);
          setConfirmationMobileModalOpen(false)
          setSelectedItem(null);
  };

  deleterequest.onerror = () => {
    console.error('Error updating record in IndexedDB');
  };

}
   

  };

  const handleCancelDelete = () => {

    setConfirmationModalOpen(false);
setConfirmationMobileModalOpen(false)
    setSelectedItem(null);

  };
  const handleCancelOfflineDelete = () => {

    setConfirmationModalOpenOffline(false);

  };
  const handleEdit = (data) => {
    if(isOnline){
     
      setSelectedRowData(data);
  
      let url = ApiURL + `/get_current_trial_details/${data.id}`;
  
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
)
        .then((response) => {
          setCurrentTrialData(response.data);
  
        })
        .catch((error) => {
          setCurrentTrialData([]);
        });
  
  
  
      setOpen(true);

    }else{
      setSelectedRowData(data);
      setCurrentTrialData(trialOfflineData[data.id-1])
      setOpenOffline(true);

    }
   
  
  };

  // if(!isOnline && trialOfflineData.length>0){
  //   const hasDuplicateIds = (dataArray) => {
  //     const idSet = new Set();
  //     for (const item of dataArray) {
  //       if (idSet.has(item.id)) {
  //         return true; // Duplicate found
  //       }
  //       idSet.add(item.id);
  //     }
  //     return false;
  //   };

  //   // Update only if there are no duplicate IDs in the combined data
  //   const dupresult=hasDuplicateIds(trialOfflineData);
  //   if(dupresult===true){
  //     getTrialOfflinedetails();
  //   }
  // }
 
  const handleEditClick = (rowData) => {
    if(isOnline){
     
      setSelectedRowData(rowData);
      const afgpInnerRows = groupedRows[expandedRowData.area]?.innerRows;
  
      // Retrieve the innerRow at index 3
      const innerRowAtIndex = afgpInnerRows[rowData.id - 1];
      if (rowData && rowData.id) {
        const element = trialData.find((item) => item.id === innerRowAtIndex.id);
        const trialId = element.id;
        let url = ApiURL + `/get_current_trial_details/${trialId}`;
  
        axios.get(url,{
          headers: {
            Authorization: `Bearer ${access_token}`,
           
          }
        }
  )
          .then((response) => {
            setCurrentTrialData(response.data);
  
          })
          .catch((error) => {
            setCurrentTrialData([]);
          });
  
      }
  
      setOpen(true);
    }
    else{
     
      // const currentdata=trialOfflineData.find((data)=>data.image.id=rowData.image.id)
      setSelectedRowData(rowData);
      setCurrentTrialData(trialOfflineData[rowData.id-1])
      setOpenOffline(true);

    }
  };
  const handleSubmitTrialDetails = (newTrail) => {

    
    
      setTrialData(newTrail);
      setCurrentTrialData([])
    if(isOnline){
      getzoneTableDetails();
    }
    
     
    
    

  };
  useEffect(() => {
    // getimageDetails();
    getzoneTableDetails();
  }, [isOnline]);
  
  const getzoneTableDetails = () => {
    let url = ApiURL + `/show_zone`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      setZoneTableData(response.data);
    }).catch((error) => {
      setZoneTableData([]);
    });
  };
  let zoneresponse;
  const getAllzoneTableDetails = () => {
    let url = ApiURL + `/show_zone`;
    axios.get(url).then((response) => {
      zoneresponse=[response.data];
    }).catch((error) => {
    });
  };

//   const getimageDetails = () => {
//     let url = ApiURL + '/show_image';
//     axios.get(url,{
//       headers: {
//         Authorization: `Bearer ${access_token}`,
//         secret_key:secret_key
//       }
//     }
// ).then((response) => {
//       setImageFileData(response.data);
//     }).catch((error) => {
//       setImageFileData([]);
//     });
//   };

  const gettrialDetails = () => {
    if (userlogoutdata) {
      let url = ApiURL + `/show_trial_details/${userlogoutdata}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
).then((response) => {
        setTrialData(response.data);
      }).catch((error) => {
        setTrialData([]);
      });
    }
    else {
      let url = ApiURL + `/show_trial_details/${PlantId}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
).then((response) => {
        setTrialData(response.data);
      }).catch((error) => {
        setTrialData([]);
      });
    }

  };
  const getAlltrialDetailsId = () => {
      let url = ApiURL + `/show_trial_details_id`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
).then((response) => {
if(response.status===200 && response.data.length>0){
  localStorage.setItem("trialdetailsallid",JSON.stringify(response.data))
}
      }).catch((error) => {
        
      });
  };

  const gettrialFilterAreaDetails = () => {
    if (userlogoutdata) {
      let url = ApiURL + `/show_trial_details_filter_area/${userlogoutdata}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
).then((response) => {
        setFilterAreaList(response.data);
      }).catch((error) => {
        setFilterAreaList([]);
      });
    }
    else {
      let url = ApiURL + `/show_trial_details_filter_area/${PlantId}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
).then((response) => {
        setFilterAreaList(response.data);
      }).catch((error) => {
        setFilterAreaList([]);
      });
    }

  };
  const gettrialFilterZoneDetails = () => {
    if (userlogoutdata) {
      let url = ApiURL + `/show_trial_details_filter_zone/${userlogoutdata}`;
      url += `?selectedAreas=${selectedAreas.join(',')}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
).then((response) => {
        setFilterZoneList(response.data);
      }).catch((error) => {
        setFilterZoneList([]);
      });
    }
    else {
      let url = ApiURL + `/show_trial_details_filter_zone/${PlantId}`;
      url += `?selectedAreas=${selectedAreas.join(',')}`;
      axios.get(url,selectedAreas,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
).then((response) => {
        setFilterZoneList(response.data);
      }).catch((error) => {
        setFilterZoneList([]);
      });
    }

  };
  const gettrialsDetails = () => {
    let url = ApiURL + `/show_trial_details/${userlogoutdata}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      setTrialData(response.data);
    }).catch((error) => {
      setTrialData([]);
    });
  };
  const gettrialTableDetails = () => {
    if (userlogoutdata) {
      let url = ApiURL + `/show_trial/${userlogoutdata}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
).then((response) => {
        setTrialTableData(response.data);
      }).catch((error) => {
        setTrialTableData([]);
      });
    }
    else {
      let url = ApiURL + `/show_trial/${PlantId}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
).then((response) => {
        setTrialTableData(response.data);
      }).catch((error) => {
        setTrialTableData([]);
      });
    }
  };
  const getAreaForPlant = (plantId) => {
    let url = ApiURL + `/show_area_plant/${plantId}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      setAreaData(response.data.sort((a, b) => a.area.localeCompare(b.area)));
    }).catch((error) => {
      setAreaData([]);
    });
  };
  const getAllAreas = (plantId) => {
    let url = ApiURL + `/show_area_name/${userlogoutdata}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
     
      }
    }
).then((response) => {
      setOnlineAreaData(response.data);
    }).catch((error) => {
      setOnlineAreaData([]);
    });
  };
 

  
  const deleteTrial = (trialId) => {
    const url = ApiURL + '/delete_trial'; // Replace with your backend API endpoint to delete area
    axios
      .delete(url, { data: { trialId: trialId },headers: {
        Authorization: `Bearer ${access_token}`,
       
      } }
)
      .then((response) => {
        if (response.status === 200) {
          setAlertOpen(true);
          if (selectedYear !== '' && area === '12' && selectedYear!=='All Year') {
            getTrialPlantDateDetails(userlogoutdata, selectedYear);
            getTrialTablePlantDetails(userlogoutdata);
        } else if (area !== null && selectedYear !== ''&& area !=='12' && selectedYear!=='All Year' ) {
            getTrialAreaDateDetails(area, selectedYear);
            gettrialTableDetails()
            
            // getTrialAreaDetails(area);
        } else if(area === null && selectedYear !== ''){
getTrialYearDetails(selectedYear)
gettrialTableDetails()
        }
      else if(area!==null&& selectedYear === ''){
        getTrialAreaDetails(area)
        gettrialTableDetails()
      }
      else if(area!==null&& selectedYear === 'All Year'){
        getTrialAreaDetails(area)
        gettrialTableDetails()
      }
        else {
            gettrialsDetails();
            gettrialTableDetails()
        }
          // gettrialsDetails();

          setAlertMessage('inspection deleted successfully')
          setConfirmationModalOpen(false);
          setConfirmationMobileModalOpen(false)
          setSelectedItem(null);
          
        }
      })
      .catch((error) => {
        // Handle error
        console.error('Failed to delete area:', error);
      });
  };
  useEffect(() => {
    getPlantDetails();
    getBusinessDetails();
    gettrialYears();
    getAreaForPlant(userlogoutdata);
    getAllAreas()
  }, [isOnline]);
  const getPlantDetails = () => {
    let url = ApiURL + '/show_plant';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
   
      }
    }
).then((response) => {
      setPlantData(response.data);
    }).catch((error) => {
      setPlantData([]);

    });
  };

  const getBusinessDetails = () => {
    let url = ApiURL + '/show_business';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
  
      }
    }
).then((response) => {
      setBusinessData(response.data);
    }).catch((error) => {
      setBusinessData([]);
    });
  };
  const gettrialYears = () => {
    let url = ApiURL + '/show_trial_details_year';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      const yearOptionsWithAllYear = [{ year: 'All Year' }, ...response.data];
      setYearsOptions(yearOptionsWithAllYear);
    }).catch((error) => {
      setYearsOptions([]);
    });
  };
  const getTrialPlantDetails = (plants) => {
    const plantId = plants;
    let url = ApiURL + `/show_trial_details_plant/${plantId}`;
    axios.get(url).then((response) => {
      setTrialData(response.data);
    }).catch((error) => {
      setTrialData([]);
    });
  };
  const getTrialTablePlantDetails = (plants) => {
    const plantId = plants;
    let url = ApiURL + `/show_trial_plant/${plantId}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      setTrialTableData(response.data);
    }).catch((error) => {
      setTrialTableData([]);
    });
  };
  const getTrialAreaDetails = (areas) => {
    const areaId = areas;
    if(userlogoutdata){
      let url = ApiURL + `/show_trial_details_area/${areaId}/${userlogoutdata}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
).then((response) => {
        setTrialData(response.data);
      }).catch((error) => {
        setTrialData([]);
      });
    }
    else{
      let url = ApiURL + `/show_trial_details_area/${areaId}/${plantId}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
       
        }
      }
).then((response) => {
        setTrialData(response.data);
      }).catch((error) => {
        setTrialData([]);
      });
    }
 
  };
  const getTrialYearDetails = (years) => {
    const year = years;
    if (userlogoutdata){
      let url = ApiURL + `/show_trial_details_yearselect/${year}/${userlogoutdata}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
       
        }
      }
).then((response) => {
        setTrialData(response.data);
      }).catch((error) => {
        setTrialData([]);
      });
    }
    else{
      let url = ApiURL + `/show_trial_details_yearselect/${year}/${plantId}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
).then((response) => {
        setTrialData(response.data);
      }).catch((error) => {
        setTrialData([]);
      });
    }
  
  };
  const getTrialTableAreaDetails = (areas) => {
    const areaId = areas;
    let url = ApiURL + `/show_trial_area/${areaId}`;
    axios.get(url).then((response) => {
      setTrialTableData(response.data);
    }).catch((error) => {
      setTrialTableData([]);
    });
  };
  const getTrialTableYearDetails = (areas) => {
    const areaId = areas;
    let url = ApiURL + `/show_trial_year/${areaId}`;
    axios.get(url).then((response) => {
      setTrialTableData(response.data);
    }).catch((error) => {
      setTrialTableData([]);
    });
  };
  const getTrialTableAreaYearDetails = (areas,years) => {
    const areaId = areas;
    const yearId=years
    let url = ApiURL + `/show_trial_year/${areaId}/${yearId}`;
    axios.get(url).then((response) => {
      setTrialTableData(response.data);
    }).catch((error) => {
      setTrialTableData([]);
    });
  };
  const getTrialAreaDateDetails = (areas, Year) => {
  
    const areaId = areas;
    const year = Year;
    if(userlogoutdata){
      let url = ApiURL + `/show_trial_details_area/${areaId}/${year}/${userlogoutdata}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
).then((response) => {
        setTrialData(response.data);
      
      }).catch((error) => {
        setTrialData([]);
      });
    }
    else{
      let url = ApiURL + `/show_trial_details_area/${areaId}/${year}/${plantId}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
).then((response) => {
        setTrialData(response.data);
      }).catch((error) => {
        setTrialData([]);
      });
    }
 
  };
  const getTrialPlantDateDetails = (plants,Year) => {
    const plantId = plants;
    const year = Year;
    let url = ApiURL + `/show_trial_details_plant/${plantId}/${year}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
     
      }
    }
).then((response) => {
      setTrialData(response.data);
    }).catch((error) => {
      setTrialData([]);
    });
  };

  if (areaData.length === 0) {
    gettrialDetails();
    gettrialTableDetails();
    gettrialFilterAreaDetails();
    // gettrialFilterZoneDetails();
    
  }


  useEffect(() => {
    if (isOnline) {
      getAlltrialDetailsId();
    }
  }, [isOnline]);
  useEffect(() => {
    if ( selectedYear===''&&area && area !== '12' && areaData.length > 0) {
      getTrialAreaDetails(area);
      gettrialTableDetails()
    }
  }, [area, areaData]);
  useEffect(() => {
    if ( selectedYear && selectedYear==='All Year'&&area && area !== '12' && areaData.length > 0) {
      getTrialAreaDetails(area);
      gettrialTableDetails()
    }
  }, [area, areaData,selectedYear]);
  useEffect(() => {
    if (selectedYear && selectedYear !== 'All Year'&& area===null && yearOptions.length > 0) {
      getTrialYearDetails(selectedYear);
      
      gettrialTableDetails()
    }
  }, [selectedYear, yearOptions]);
  useEffect(() => {
    if (selectedYear&&area===null && selectedYear === 'All Year' && yearOptions.length > 0) {
      gettrialDetails()
      gettrialTableDetails()
    }
  }, [selectedYear, yearOptions]);

 useEffect(() => {
    if ( selectedYear === 'All Year' && area === '12') {
      gettrialDetails()
      gettrialTableDetails()
    }
  }, [selectedYear, area]);
  useEffect(() => {
    if ( area === '12' && selectedYear === 'All Year') {
      gettrialDetails()
      gettrialTableDetails()
    }
  }, [area, selectedYear]);
  useEffect(() => {
    if (area && area === '12' && areaData.length > 0 && selectedYear==='')  {
      gettrialDetails()
      gettrialTableDetails()
    }
  }, [area, areaData]);
 
  useEffect(() => {
    if (area && area !== '12' && areaData.length > 0 && selectedYear && selectedYear !== 'All Year') {
      getTrialAreaDateDetails(area, selectedYear);
      gettrialTableDetails()
      
    }
  }, [area, areaData, selectedYear]);
  
  // useEffect(() => {
  //   if (area !== null && selectedYear !== '' && area !== '12' && areaData.length > 0) {
  //     getTrialAreaDateDetails(area, selectedYear);
      
  //     gettrialTableDetails()
  //   }
  // }, [area, selectedYear, areaData]);
 

  // useEffect(() => {
  //   if (area && area !== '12' && areaData.length > 0 && selectedYear) {
  //     getTrialAreaDateDetails(area, selectedYear);
  //     getTrialTableAreaDetails(area);
  //   }
  // }, [area, areaData, selectedYear]);

  useEffect(() => {
    if (area && area === '12' && areaData.length > 0 && selectedYear && selectedYear !== 'All Year') {
      getTrialPlantDateDetails(userlogoutdata, selectedYear);
      gettrialTableDetails()
    }
  }, [area, areaData, selectedYear]);
  useEffect(() => {
    if (selectedYear && areaData.length > 0 && area && area === '12' && selectedYear !== 'All Year') {
      getTrialPlantDateDetails(userlogoutdata, selectedYear);
      gettrialTableDetails()
      
    }
  }, [selectedYear, areaData,area ]);

 
 
 
  const outerColumns = [
    {
      field: 'actions',
      headerName: 'Expand/Collapse',
      align:'center',
      flex: 1,
     
      renderCell: (params) => (
        <div>
          <IconButton size="small" onClick={() => handleExpand(params.row.id)}>
            {expanded.includes(params.row.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </div>
      ),
    },
    {
      field: 'area',
      headerName: 'Area',
      align: "center",
      flex: 3,
      
      
    },
    {
      field: 'description',
      headerName: 'Description',
      align: "center",
      
      flex: 3,
      
    },
    {
      field: 'ni',
      headerName: 'Ni %',
      align: "center",
      flex: 3,
     
      
      cellClassName: (params) => {
        const niValue = parseFloat(params.value);

        if (niValue > 1) {
          return 'cell-red';
        } else if (niValue >= 0.1 && niValue <= 1) {
          return 'cell-orange';
        } else if (niValue >= 0.05 && niValue <= 0.1) {
          return 'cell-blue';
        } else {
          return 'cell-green';
        }
      },
    },
    // {
    //   field: 'remarksValue',
    //   headerName: 'Added By',
    //   align: "center",
    //   flex: 3,
     
    // },


  ];

  // Define columns for the inner DataGrid (content of the collapsed section)
  const innerColumns = [
    { field: 'id',
     headerName: 'Sno',
      minWidth: 150,
      cellClassName: 'custom-column-style',
     
    
     },
    { field: 'zone',
     headerName: 'Zone',
     minWidth: 150, 
     cellClassName:'custom-column-style',
    
    },
    { field: 'status',
     headerName: 'Status',  
     minWidth: 155,
     cellClassName: 'custom-column-style',
   
  },
    { field: 'xrfNumber', 
    headerName: 'XRF Number',
     minWidth: 150, 
     cellClassName:'custom-column-style',
      
  },
     
    {
      field: 'ni',
      headerName: 'Ni %',
      minWidth: 150,
      cellClassName: (params) => {
        const niValue = parseFloat(params.value);

        if (niValue > 1) {
          return 'cell-red';
        } else if (niValue >= 0.1 && niValue <= 1) {
          return 'cell-orange';
        } else if (niValue >= 0.05 && niValue <= 0.1) {
          return 'cell-blue';
        } else {
          return 'cell-green';
        }
      },
     
    },
    { field: 'comments',
     headerName: 'Comments',   
    minWidth: 150, 
    cellClassName:'custom-column-style',
      
   },
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 150,
      align: "center",
      renderCell: (params) => {
        const niValue = parseFloat(params.row.ni);
        let categoryValue = '';
        let chipColor = ''; // Define a variable to hold the chip color

        if (niValue > 1) {
          categoryValue = '>1%';
          chipColor = '#E74C3C'; // Set the chip color based on the category
        } else if (niValue >= 0.1 && niValue <= 1) {
          categoryValue = '0.1-1.0';
          chipColor = '#E67E22'; // Set the chip color based on the category
        } else if (niValue >= 0.05 && niValue <= 0.1) {
          categoryValue = '0.05-0.1';
          chipColor = '#3498DB'; // Set the chip color based on the category
        } else {
          categoryValue = '<0.05';
          chipColor = '#2ECC71'; // Set the chip color based on the category
        }

        return (
          <Chip
            label={categoryValue}
            color="primary" // You can adjust this color as needed
            style={{ backgroundColor: chipColor, color: 'white' }}
          />
        );
      },
      
    },
    { field: 'addedby',
     headerName: 'AddedBy',   
    minWidth: 150, 
    cellClassName:'custom-column-style',
      
   },
    { field: 'addedon', 
    headerName: 'AddedOn',  
    minWidth: 150, 
    cellClassName: 'custom-column-style',
    
  },

    // Add more columns as needed
    {
      field: 'actions', // This is a special field for custom rendering
      headerName: 'Image',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            <IconButton size="small" onClick={() => { handleImageIconClick(params.row); setOpenImageModal(true) }} >
              <ImageIcon />
            </IconButton>
          </div>
        );
      },
      
    },

    {

      field: 'action',
      headerName: 'Action',
      minWidth: 150,
      renderCell: (params) => (

          <>

            <div style={{ alignItems: "center" }}>
            {isOnline &&
            <span style={{ marginRight: "10px" }}>
            <ViewIcon onClick={() => { handleViewIconClick(params.row); setOpenViewIconModal(true) }} style={{ cursor: "pointer" }} />
          </span>
            }
              
              {iconsVisibleTrial &&
              <span style={{ marginRight: "10px" }}>
                <EditIcon onClick={() => handleEditClick(params.row)} style={{ cursor: "pointer" }} />
              </span>
    }{iconsVisibleTrial &&
              <DeleteIcon

                variant="contained"

                color="error"
                onClick={() => {

                  setSelectedItem(params.row.id);

                  setConfirmationModalOpen(true);

                }}
                style={{ cursor: "pointer" }}

              />
              }
            </div>

          </>

      ),

    },
  ];

  let outerRowCounter = 1; // Initialize the counter variable outside the reduce function
 useEffect(() => {
    
    // Initialize an empty object to store grouped rows

if(isOnline){
  const groupedRow = {};
  trialData.forEach(trial => {
    const area = trial.area;

    // Check if the area exists in the groupedRows object
    if (!groupedRow[area]) {
      // If not, initialize an array for that area
      groupedRow[area] = [];
    }
  
    // Push the trial into the array corresponding to its area
    groupedRow[area].push(trial);
  });
  let nextId = 1;
function generateUniqueId() {
  return nextId++;
}
// Iterate over each trial in TrialData

const dataConsole = Object.entries(groupedRow).reduce((acc, [key, value]) => {
  const innerRows = value.map(item => {
    // const dateStr = item.date.split("T")[0]; // Assuming date is in the format "YYYY-MM-DD"
    // console.log("todays date",item.date)
    // const currentDate = new Date(dateStr); // Parse the date string
    
    // // Increase the date by one day
    // currentDate.setDate(currentDate.getDate() + 1);
    
    // // Format the increased date back to the desired format
    // const increasedDateStr = currentDate.toISOString().split('T')[0];
    const innerRow = {
      id: item.id,
      zone: item.zone,
      status: item.remark,
      xrfNumber: item.xrf_number,
      ni: item.ni,
      category: 'category value',
      addedby: item.name,
      addedon: item.formatted_date,
      comments:item.comments,
      // Add more fields as needed
    };

    return innerRow;
  });
  
  const areaDescription = key; // Assuming area description is the same as the key
  const niTotal = innerRows.reduce((acc, curr) => acc + parseFloat(curr.ni), 0); // Calculating total ni
  const niAverage = niTotal / innerRows.length;

  const outerRow = {
    id: generateUniqueId(), // Generate unique ID for outer row
    area: areaDescription,
    description: value[0].description,
    ni: niAverage,
  }; // Assuming outerRow is empty for now

  const uniqueInnerRowIds = new Set(innerRows.map(row => row.id)); // Extract unique inner row IDs
  
  acc[key] = { innerRows, outerRow, uniqueInnerRowIds, area: key }; // Constructing each dataConsole object using the area key as index

  return acc;
}, {});



 
    setGroupedRows(dataConsole);
}
if(!isOnline){
  const groupedRow = {};
  trialOfflineData.forEach(trial => {
    const area = trial.area.area;

    // Check if the area exists in the groupedRows object
    if (!groupedRow[area]) {
      // If not, initialize an array for that area
      groupedRow[area] = [];
    }
  
    // Push the trial into the array corresponding to its area
    groupedRow[area].push(trial);
  });
  let nextId = 1;
function generateUniqueId() {
  return nextId++;
}
// Iterate over each trial in TrialData

const dataConsole = Object.entries(groupedRow).reduce((acc, [key, value]) => {
  const innerRows = value.map(item => {
    const dateStr = item.date.split("T")[0]; // Assuming date is in the format "YYYY-MM-DD"
    const currentDate = new Date(dateStr); // Parse the date string
    
    // Increase the date by one day
    currentDate.setDate(currentDate.getDate() + 1);
    
    // Format the increased date back to the desired format
    const increasedDateStr = currentDate.toISOString().split('T')[0];
    const innerRow = {
      id: item.id,
      zone: item.zone.zone,
      status: item.remarksValue,
      xrfNumber: item.xrfNumber,
      ni: item.Ni,
      category: 'category value',
      addedby: userDet.name,
      addedon: item.date,
      comments:item.comments,
      image:item.image

      // Add more fields as needed
    };

    return innerRow;
  });
  
  const areaDescription = key; // Assuming area description is the same as the key
  const niTotal = innerRows.reduce((acc, curr) => acc + parseFloat(curr.ni), 0); // Calculating total ni
  const niAverage = niTotal / innerRows.length;

  const outerRow = {
    id: generateUniqueId(), // Generate unique ID for outer row
    area: areaDescription,
    description: value[0].area.description,
    ni: niAverage,
  }; // Assuming outerRow is empty for now

  const uniqueInnerRowIds = new Set(innerRows.map(row => row.id)); // Extract unique inner row IDs
  
  acc[key] = { innerRows, outerRow, uniqueInnerRowIds, area: key }; // Constructing each dataConsole object using the area key as index

  return acc;
}, {});


localStorage.setItem('lenofflinedata',trialOfflineData.length)
setofflineInspectionlength(trialOfflineData.length)
 
    setGroupedRows(dataConsole);

}

    }, [isOnline,trialData,trialTableData,trialOfflineData]);  


  const outerRows = Object.values(groupedRows).map((group) => group.outerRow);
  const innerRows = Object.values(groupedRows).flatMap((group) => group.innerRows);

 

  const handleExpand = (index) => {
    if (expanded.includes(index)) {
      setExpanded([]);
    } else {
      setExpanded([index]);
    }
    const outerRowData = outerRows.find((row) => row.id === index);
    setExpandedRowData(outerRowData);
  };
  const filteredOuterRows = outerRows.filter((outerRow) => groupedRows[outerRow.area]?.innerRows.length > 0);
  // Convert groupedRows to rows array for the outer DataGrid
  const rows = filteredOuterRows.map((outerRow) => ({
    ...outerRow,
    innerrow: [], // Initialize innerrow to an empty array
    actions: (
      <div>
        <IconButton size="small" onClick={() => handleExpand(outerRow.id)}>
          {expanded.includes(outerRow.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
    ),
  }));
  // Function to get the inner rows for a specific outer row
  const getInnerRowsForOuterRow = (outerRow) => {
    if (expanded.includes(outerRow.id)) {
      const innerRowsForOuter = groupedRows[outerRow.area]?.innerRows.map((innerRow, index) => ({
        ...innerRow,
        id: index + 1,
      })) || [];

      return innerRowsForOuter;
    }
    return [];
  };

  const newObject = { id: '12', area: 'all area' };
  const exists = areaData.some(item => item.id === newObject.id && item.area === newObject.area);
  const columnsWithHiddenHeaders = outerColumns.map((column) => {
    if (column.field === 'area' || column.field === 'description' || column.field === 'ni' || column.field === 'remarksValue' || column.field === 'actions') {
      return {
        ...column,
        headerName: '',
        // Empty string to hide the header name
      };
    }
    return column;
  });

  if (!exists) {
    areaData.push(newObject);
  }
 
 
  const columnsToUse = innerColumns
  const groupedZones = {};
filterZoneList.forEach((item) => {
  const category = item.area; // Assuming category field name in filterZoneList
  const zone = item.zone; // Assuming zone field name in filterZoneList

  if (!groupedZones[category]) {
    groupedZones[category] = [];
  }

  groupedZones[category].push(zone);
});
  function formatBusinessName(name) {
    if (name) {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }
    return ''; // Handle the case where name is undefined or null
  }
  
  const getNextDay = (selectedDate) => {
    const dateStr = selectedDate?selectedDate.split("T")[0]:''; // Assuming date is in the format "YYYY-MM-DD"
    const currentDate = dateStr ? new Date(dateStr) : null; // Parse the date string
  
    // Increase the date by one day
    if(currentDate){
      currentDate.setDate(currentDate.getDate() + 1);
  
      // Format the increased date back to the desired format
      const increasedDateStr = currentDate.toISOString().split('T')[0];
      return increasedDateStr;
    }
    
 
    
  };
 
  useEffect(()=>{
    if(isOnline && zoneTableData.length>0 && areaData.length>0){
      const dataString = JSON.stringify(zoneTableData);
  localStorage.setItem('allzoneData', dataString);
  const areaString = JSON.stringify(OnlineAreaData);
  localStorage.setItem('allareaData', areaString);
    }

  },[isOnline,zoneTableData,OnlineAreaData])


 const handleSetTrialClick = () => {
  // Update child state
  const filterselectedarea = localStorage.getItem('area');
const filterselectedyear = localStorage.getItem('selectedYear');
  getTrialAreaDateDetails(filterselectedarea,filterselectedyear)
  // setTrialData(newChildValue);

  // Call the callback function passed from the parent to update the parent state
 
};
const uniqueData = trialOfflineData.reduce((acc, current) => {
  const duplicate = acc.find(item => item.id === current.id);
  return duplicate ? acc : [...acc, current];
}, []);

  return (
    <div>
          {loading && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',position:'fixed',top:0,width:'100%',background:'#000000ab',zIndex:'9999' }}>
        <CircularProgress /> 
      </div>
    )}
      <div className="desktop-view">
  
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item >
 <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
              Inspection
            </Typography>
          </Grid>
          <Grid item xl='6' lg='6'>
            <Grid container style={{ padding: '10px 10px 10px ' }} alignItems={'center'} justifyContent={'space-between'}   >

              <Grid item md='4'  >
                <FormControl fullWidth>
                  <InputLabel id="area-label" style={modalInputLabelStyle} shrink={Boolean(area)}>Area</InputLabel>
                  <Select labelId="area-label" className='select-nis' size="small" value={area} onChange={handleAreaChange}>
                    {areaData.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.area}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
             
              <Grid item md='4'  >
                <FormControl fullWidth>
               
                  <InputLabel id="year-label" style={modalInputLabelStyle} >Year</InputLabel>
                  
                  <Select
                    size="small"
                    className='select-nis'
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    {yearOptions.map((year, index) => (
                      <MenuItem key={index} value={year.year}>
                        {year.year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>
            


              {iconsVisibleTrial && (
                <Grid item  md='3'> <Button variant="contained" startIcon={<AddIcon />} style={{ backgroundColor: '#002060' }} onClick={handleOpen}>
                  New Inspection
                </Button>
                </Grid>
              )}
              <Grid item>
              <Tooltip title={`Number Of Offline Inspections:${offlineInspectionlength}`}>
      <IconButton style={{ }}>
        <WifiOffIcon />
        <span style={{position:'absolute',top: -8, right: -8,  background: 'red', color: 'white'}}>{offlineInspectionlength}</span>
      </IconButton>
    </Tooltip>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <hr style={{ margin: '1px 0', borderColor: '#0000001A' }} />
        <Grid container justifyContent={'space-between'} alignItems={'center'} style={{ padding: '10px 0 10px  ' }}>
          <Grid item style={{ display: 'flex',justifyContent:'space-between',alignItems:'center',width:'100%' }}>
          <div style={{ flex:'1 0 0.25%'}}></div>
            <div style={{ flex: 3, alignItems: "center", flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 600, fontSize: 18, width: '100%', textAlign: 'center'}}>
                Area
              </Typography>
            </div>
            <div style={{ flex: 3, alignItems: "center", flexDirection: 'column' }} >
              <Typography sx={{ fontWeight: 600, fontSize: 18, textAlign: 'center' }}>
                Description
              </Typography>
            </div>
            <div style={{ flex: 3, alignItems: "center", flexDirection: 'column'}}>
              <Typography sx={{fontWeight: 600, fontSize: 18, textAlign: 'center' }}>
                Ni
              </Typography>
            </div>
            {/* <div style={{ flex: 3, alignItems: "center" , flexDirection: 'column'}}>
              <Typography sx={{ fontWeight: 600, fontSize: 18, textAlign: 'center'}}>
                Added By
              </Typography>
            </div> */}
            
          </Grid>
        </Grid>

        <hr style={{ margin: '1px 0', borderColor: '#0000001A' }} />

        <div>
          {/* Outer DataGrid */}
          {rows.map((outerRow, index) => (
            <div key={outerRow.id} >
              {/* Render the outer row */}
              <DataGrid
                style={{ color: "#0000008C", fontWeight: "500" }}
                rows={[outerRow]}
                columns={columnsWithHiddenHeaders}
                sty
                pageSize={20} // Display only one row for the outer row
                className="custom-datagrid trial-datagrid"

              />
              {/* Check if the outer row is expanded */}
              {expanded.includes(outerRow.id) && (
                <div  >
                  {/* Inner DataGrid (content of the collapsed section) */}
                  <DataGrid
                    rows={getInnerRowsForOuterRow(outerRow)}
                    columns={columnsToUse}
                    pageSize={5}
                    checkboxSelection

                  />
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Dialog to display the image */}
        <Dialog open={openImageModal} onClose={handleCloseImageModal}>
          <DialogContent>
            {/* Use the selectedImage state as the source of the image */}
            {selectedImage && <img src={selectedImage} alt="Uploaded Image" style={{ width: '100%' }} />}
            {!selectedImage && <div> <ImageIcon /></div>} {/* Display a message if there is no image */}
          </DialogContent>
        </Dialog>
       

        <Dialog open={confirmationModalOpen} onClose={handleCancelDelete}>

          <DialogTitle>Are you sure you want to delete?</DialogTitle>

          <DialogActions>

            <Button onClick={handleCancelDelete} >Cancel</Button>

            <Button onClick={handleConfirmDelete} variant="contained" color="primary">

              Confirm

            </Button>

          </DialogActions>

        </Dialog>

  

     



      </div>
      <div className="mobile-view">
      {/* {loading && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',position:'fixed',top:0,width:'100%',background:'#000000ab',zIndex:'9999' }}>
        <CircularProgress />
      </div>
    )} */}
       <Grid container justifyContent={'space-between'} style={{ display: 'flex' }} alignItems={'center'}>
          <Grid item  >
            
                  <Typography style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 600 }}>
                  {breadcrumb === 'view' ? 'Inspection' : breadcrumb === 'analytics' ? 'Dashboard' : formatBusinessName(breadcrumb)}
                  </Typography>
                
                </Grid>
                  
            {iconsVisibleTrial && ( <Grid item marginTop={1} style={{display:'flex'}}> <Button variant="contained"  startIcon={<AddIcon />} style={{  backgroundColor: '#002060',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              
              width: '30%',
              height: '30%',
                 }} 
     
                onClick={handleOpen}>

                </Button>
                <Tooltip title={`Number Of Offline Inspections:${offlineInspectionlength}`}>
      <IconButton style={{ }}>
        <WifiOffIcon />
        <span style={{position:'absolute',top: -1, right: -5,  background: 'red', color: 'white'}}>{offlineInspectionlength}</span>
      </IconButton>
    </Tooltip>
                </Grid>
               
                )}
                
          </Grid>
          <Grid container style={{ padding: '5px 2px 10px  ' }} alignItems={'center'} justifyContent={'space-between'}>
                  <Grid item >
                  <Typography style={{fontSize:"12px",fontWeight:400,color:"#8C8C8C"}}>
                    Get insights quickly with your Inspection Details
                  </Typography>
                  </Grid>
                    </Grid>
          <hr style={{ margin: '1px 0', borderColor: '#0000001A' }} />
         

            <Grid container style={{ padding: '5px 24px 10px  ' }} alignItems={'center'} justifyContent={'space-between'}   >

           
                <Grid item >
                    <TextField
                   
                     placeholder="Search by Area, Zone, or Date"
                      size="small"
                      className="input-nis" 
                     value={searchText}
                     onChange={(e) => setSearchText(e.target.value)}
                   />
                </Grid>
                <Grid item>
                <IconButton onClick={handleIconClick}>
        <ArrowDropDownIcon />
      </IconButton>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Select Options</DialogTitle>
        <DialogContent>
          <div>
            <h2>Areas:</h2>
            <div>
              <Checkbox
                checked={selectAllAreas}
                onChange={(event) => handleCategoryChange(event, 'area')}
                value="all"
              />
              Select All Areas
            </div>
            {Array.isArray(selectedAreas) &&
              filterAreaList.map((areas) => (
                <div key={areas}>
                  <Checkbox
                    checked={selectedAreas.includes(areas.area)}
                    onChange={(event) => handleCategoryChange(event, 'area')}
                    value={areas.area}
                  />
                  {areas.area}
                </div>
              ))}
          </div>
          <div>
            <h2>Zones:</h2>
            <div>
              <Checkbox
                checked={selectAllZones}
                onChange={(event) => handleCategoryChange(event, 'zone')}
                value="all"
              />
              Select All Zones
            </div>
            {Array.isArray(selectedZones) &&
             Object.entries(groupedZones).map(([category, zones]) => (
              <div key={category}>
                <h3>{category}</h3>
                {zones.map((zone) => (
                  <div key={zone}>
                    <Checkbox
                      checked={selectedZones.includes(zone)}
                      onChange={(event) => handleCategoryChange(event, 'zone')}
                      value={zone}
                    />
                    {zone}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

                </Grid>
              

              {iconsVisible && (
                <Grid item>
                  <FilterIcon />
                </Grid>
              )}


            </Grid>
         
          
       


        <hr style={{ margin: '1px 0', color: '#fafafa' }} />
        {/* <Grid item xs={12}>
        {isOnline?(     
  {filteredData.map((data,index) => (
    <Card key={data.id} style={cardStyle}>
      <CardContent style={contentStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px' }}>
            {data.zone}
          </div>
          <div style={topRightCornerStyle}>
          <IconButton
              aria-label="more"
              onClick={(event) => handleOpenMenu(event, data, index)} // Pass the data object and index
            >
              <MoreVertIcon style={{ color: '#000000' }} />
            </IconButton>
            <Menu anchorEl={menuAnchors[index]} open={Boolean(menuAnchors[index])} onClose={() => handleCloseMenu(index)}>
             {userDet.role_id !== "2" && <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>}
              <MenuItem onClick={() => handleView(data)}>View</MenuItem>
             { userDet.role_id !== "2" &&<MenuItem onClick={() => {
                setCurrentTrialDelete(data);
                setConfirmationMobileModalOpen(true);
              }}>Delete</MenuItem>}
            </Menu>
          </div>
        </div>

        <div style={{ alignSelf: 'flex-start' }}>
          <Typography variant="subtitle2" style={{ color: '#161616', fontSize: '16px', fontWeight: '600' }}>
            {data.area}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle2" color="textSecondary">
            Created by: {data.name}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
          {getNextDay(data.date)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  ))}
):(
  {trialOfflineData.map((data,index) => (
    <Card key={data.id} style={cardStyle}>
      <CardContent style={contentStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px' }}>
            {data.zone.zone}
          </div>
          <div style={topRightCornerStyle}>
          <IconButton
              aria-label="more"
              onClick={(event) => handleOpenMenu(event, data, index)} // Pass the data object and index
            >
              <MoreVertIcon style={{ color: '#000000' }} />
            </IconButton>
            <Menu anchorEl={menuAnchors[index]} open={Boolean(menuAnchors[index])} onClose={() => handleCloseMenu(index)}>
             {userDet.role_id !== "2" && <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>}
              <MenuItem onClick={() => handleView(data)}>View</MenuItem>
             { userDet.role_id !== "2" &&<MenuItem onClick={() => {
                setCurrentTrialDelete(data);
                setConfirmationMobileModalOpen(true);
              }}>Delete</MenuItem>}
            </Menu>
          </div>
        </div>

        <div style={{ alignSelf: 'flex-start' }}>
          <Typography variant="subtitle2" style={{ color: '#161616', fontSize: '16px', fontWeight: '600' }}>
            {data.area}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle2" color="textSecondary">
            Created by: {data.name}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
          {getNextDay(data.date)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  ))}
)
   
}
</Grid> */}
{/* <Grid item>
{filteredData.map((data,index) => (
    <Card key={data.id} style={cardStyle}>
      <CardContent style={contentStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px' }}>
            {data.zone}
          </div>
          <div style={topRightCornerStyle}>
          <IconButton
              aria-label="more"
              onClick={(event) => handleOpenMenu(event, data, index)} // Pass the data object and index
            >
              <MoreVertIcon style={{ color: '#000000' }} />
            </IconButton>
            <Menu anchorEl={menuAnchors[index]} open={Boolean(menuAnchors[index])} onClose={() => handleCloseMenu(index)}>
             {userDet.role_id !== "2" && <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>}
              <MenuItem onClick={() => handleView(data)}>View</MenuItem>
             { userDet.role_id !== "2" &&<MenuItem onClick={() => {
                setCurrentTrialDelete(data);
                setConfirmationMobileModalOpen(true);
              }}>Delete</MenuItem>}
            </Menu>
          </div>
        </div>

        <div style={{ alignSelf: 'flex-start' }}>
          <Typography variant="subtitle2" style={{ color: '#161616', fontSize: '16px', fontWeight: '600' }}>
            {data.area}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle2" color="textSecondary">
            Created by: {data.name}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
          {getNextDay(data.date)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  ))}
</Grid> */}
<Grid item>
  {isOnline ? (
 filteredData.map((data,index) => (
  <Card key={data.id} style={cardStyle}>
    <CardContent style={contentStyle}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ marginBottom: '10px' }}>
          {data.zone}
        </div>
        <div style={topRightCornerStyle}>
        <IconButton
            aria-label="more"
            onClick={(event) => handleOpenMenu(event, data, index)} // Pass the data object and index
          >
            <MoreVertIcon style={{ color: '#000000' }} />
          </IconButton>
          <Menu anchorEl={menuAnchors[index]} open={Boolean(menuAnchors[index])} onClose={() => handleCloseMenu(index)}>
           {userDet.role_id !== 2 && <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>}
            <MenuItem onClick={() => handleView(data)}>View</MenuItem>
           { userDet.role_id !== 2 &&<MenuItem onClick={() => {
              setCurrentTrialDelete(data);
              setConfirmationMobileModalOpen(true);
            }}>Delete</MenuItem>}
          </Menu>
        </div>
      </div>

      <div style={{ alignSelf: 'flex-start' }}>
        <Typography variant="subtitle2" style={{ color: '#161616', fontSize: '16px', fontWeight: '600' }}>
          {data.area}
        </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2" color="textSecondary">
          Created by: {data.name}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
        {data.formatted_date}
        </Typography>
      </div>
    </CardContent>
  </Card>
))
  ) : (
    uniqueData.map((data,index) => (
      <Card key={data.id} style={cardStyle}>
        <CardContent style={contentStyle}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ marginBottom: '10px' }}>
              {data.zone.zone}
            </div>
            <div style={topRightCornerStyle}>
            <IconButton
                aria-label="more"
                onClick={(event) => handleOpenMenu(event, data, index)} // Pass the data object and index
              >
                <MoreVertIcon style={{ color: '#000000' }} />
              </IconButton>
              <Menu anchorEl={menuAnchors[index]} open={Boolean(menuAnchors[index])} onClose={() => handleCloseMenu(index)}>
               {userDet.role_id !== 2 && <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>}
                <MenuItem onClick={() => handleView(data)}>View</MenuItem>
               { userDet.role_id !== 2 &&<MenuItem onClick={() => {
                  setCurrentTrialDelete(data);
                  setConfirmationMobileModalOpen(true);
                }}>Delete</MenuItem>}
              </Menu>
            </div>
          </div>
  
          <div style={{ alignSelf: 'flex-start' }}>
            <Typography variant="subtitle2" style={{ color: '#161616', fontSize: '16px', fontWeight: '600' }}>
              {data.area.area}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2" color="textSecondary">
              Created by: {userDet.name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
            {data.date}
            </Typography>
          </div>
        </CardContent>
      </Card>
    ))
  )}
</Grid>

        

        <Dialog open={confirmationMobileModalOpen} onClose={handleCancelDelete}>

          <DialogTitle>Are you sure you want to delete?</DialogTitle>

          <DialogActions>

            <Button onClick={handleCancelDelete} >Cancel</Button>

            <Button onClick={handleConfirmMobileDelete} variant="contained" color="primary">

              Confirm

            </Button>

          </DialogActions>

        </Dialog>





      
      </div>
      <AddTrial open={open} onclose={handleClose} selectedRowData={selectedRowData} currenttrialData={currenttrialData} trialData={trialData} onHandleTrial={gettrialTableDetails} onHandleTrialDetails={gettrialDetails} onSubmit={handleSubmitTrialDetails} resetFormFields={resetFormFields} onShowAlertMessage={handleOpenAlert} />
        {!isOnline&&(
 <AddTrialOffline open={openOffline} onclose={handleClose} selectedRowData={selectedRowData} currenttrialData={currenttrialData} trialData={trialData} onHandleTrial={gettrialTableDetails} onHandleTrialDetails={getTrialOfflinedetails} onSubmit={handleSubmitTrialDetails} resetFormFields={resetFormFields} onShowAlertMessage={handleOpenAlert} db={db}/>
        )}
        <Snackbar
          open={alertOpen}
          autoHideDuration={600}
          onClose={handleCloseAlert}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseAlert}
            severity="success"
          >
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      <Dialog open={openViewIconModal} onClose={handleCloseViewIconModal}>
          <DialogTitle className={getTitleColor(currentViewData?.[0]?.ni)}>
          <Grid container style={{ display: 'flex', justifyContent: 'space-between' , alignItems: 'center'}}>
              {currentViewData.length > 0 ? (
                <Grid item xs={10} style={{paddingRight: '15px'}}>
                  <Grid item style={{fontSize: '18px'}}>{currentViewData[0].zone}</Grid>
                  <Grid item style={{fontSize: '11px',lineHeight: '14px'}}>{currentViewData[0].description}</Grid>
                  </Grid>
              ) : (
                <Grid item>
                  <Grid>No data available</Grid>
                </Grid>
              )}
              <Grid item xs={2} style={{padding: '0px 13px', borderLeft:'2px solid #fc8888', textAlign: 'center'}}>
                <Grid item style={{fontSize: '21px'}}>NI (%)</Grid>
                {currentViewData.length > 0 ? (
                  <Grid item style={{fontSize: '18px'}}>{currentViewData[0].ni}</Grid>
                ) : (
                  <Grid>N/A</Grid>
                )}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {currentViewData.length > 0 ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{fontWeight:"400",color:"#000000"}}>XRF Number: {currentViewData[0].xrf_number}</div>
                <div>Date: {currentViewData[0].formatted_date}</div>
              </div>
            ) : (
              <div>
                <div>No data available</div>
              </div>
            )}
            <div>
              {selectedImage && <img src={selectedImage} alt="Uploaded Image" style={{ width: '80%' }} />}
              {!selectedImage && <div> <ImageIcon /></div>} {/* Display a message if there is no image */}
            </div>
            {currentViewData.length > 0 && currentViewData[0].comments && (
  <div>
    <div>Comment</div>
    <TextField
      size="small"
      className="input-nis"
      value={currentViewData[0].comments}
      fullWidth
      style={{ marginBottom: 16 }}
    />
  </div>
)}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showHistory}
                    onChange={handleCheckboxChange}
                    name="showHistory"
                  />
                }
                label="Show History"
              />
              {showHistory && (
                <div>
                  {currentHistoryImageData.map((trial, index) => {
                    return (
                      <div key={index}>
                        <img src={trial} alt={`Uploaded Image ${index + 1}`} style={{ width: '100%' }} />
                        <p>Date: {currentHistoryViewData[index].formatted_date}</p>
                      </div>
                    );

                
                  })}
                  {currentHistoryViewData.length === 0 && <div> <ImageIcon /></div>}
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
    </div>
  );
};
export default View;