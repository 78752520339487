import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SearchIcon } from "../../assets/img/search.svg";
import { ReactComponent as FilterIcon } from "../../assets/img/filter.svg";
import { ReactComponent as EditIcon } from '../../assets/img/Edit.svg';
import { useRecoilState, useRecoilValue } from "recoil";
import { ReactComponent as DeleteIcon } from '../../assets/img/Delete.svg';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import configParam from '../../config';
import { Collapse, IconButton, Typography, TableCell } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { plantId } from '../../recoil/atoms';
import {
  AppBar,
  Toolbar,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DatePicker from '@mui/lab/DatePicker';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ImageIcon from '@mui/icons-material/Image';
import axios from "axios";
import CaptureImage from './CaptureImage';

import { useNavigate, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
const moment = require('moment');
const areas = ['Area 1', 'Area 2', 'Area 3'];
const remarks = ['Accessible', 'Not access', 'Not in contact', 'Others'];
const filter = createFilterOptions();
const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: 20,
  maxWidth: 500,
  width: '90%',
  maxHeight: '80vh',
  overflow: 'auto',
};

const modalInputLabelStyle = {

  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#000',
};
const imageStyle = {
  width: '100%', // Set the width to 100% of its container
  height: 'auto',  // Let the height adjust to maintain the aspect ratio
  display: 'block', // Remove any extra spacing or borders
};
const currentday = new Date().toISOString().split('T')[0];

const AddTrialOffline = ({ open, onclose, selectedRowData, currenttrialData, trialData, onHandleTrial, onHandleTrialDetails, onSubmit, resetFormFields, onShowAlertMessage,db}) => {
  const navigate = useNavigate();
  const [area, setArea] = useState(null);
  const [description, setDescription] = useState('');
  const [zone, setZone] = useState('');
  const [xrfNumber, setXrfNumber] = useState('');
  const [Ni, setNi] = useState('');
  const [remarksValue, setRemarksValue] = useState('');
  const [comments, setComments] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [trailData, setTrailData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const datauser=localStorage.getItem("user_id")
  let data = localStorage.getItem('user');
  let User = JSON.parse(data);
  const userlogoutdata = datauser!=null?datauser:User.plant_id[0];
  const [trialTableData, setTrialTableData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [descriptionData, setDescriptionData] = useState([]);
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [imageFilePath, setImageFilePath] = useState('');
  const [openImageModal, setOpenImageModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState('');
  const [imageFileData, setImageFileData] = useState('');
  const [fileData, setFileData] = useState('');
  const [expandedRowData, setExpandedRowData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageImage, setErrorMessageImage] = useState('');
  const [errorMessageDescription, setErrorMessageDescription] = useState('');
  const [errorMessageZone, setErrorMessageZone] = useState('');
  const [errorMessageXrf, setErrorMessageXrf] = useState('');
  const [errorMessageNi, setErrorMessageNi] = useState('');
  const [errorMessageRemark, setErrorMessageRemark] = useState('');
  const [errorMessageComments, setErrorMessageComments] = useState('');
  const [errorMessageDate, setErrorMessageDate] = useState(null);
  const [inputValueZone, setInputValueZone] = useState('');
  const [inputZone, setInputZone] = useState(null);
  const [isInputValueZoneInitialized, setInputValueZoneInitialized] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [PlantId, setPlantId] = useRecoilState(plantId);
  const [userDet, setUserDet] = useState({});
  const [isCursorInsideDialog, setIsCursorInsideDialog] = useState(false);
  const [cloudActive, setCloudActive] = useState(false);
  const [captureActive, setCaptureActive] = useState(false);
  const [areaDataStatus,setareaDataStatus]=useState(false);
  // const [zoneTableData, setZoneTableData] = useState(zonetabledetails);
  const [imgid, setImgid] = useState(null);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  const allzonedetails = localStorage.getItem('allzoneData');

  // Parse the string back to an array using JSON.parse()
  const storedzoneArray = JSON.parse(allzonedetails);
  const allareadetails = localStorage.getItem('allareaData');

  // Parse the string back to an array using JSON.parse()
  const storedareaArray = JSON.parse(allareadetails);
  const dbName = 'nis';
  const areaName = 'area';
  const zoneName = 'zone';
  const imageName = 'image';
  const trialName = 'trial';
  const trialDetailsName = 'trialDetails';

  // useEffect(() => {
  //   if (!isOnline) {
  //       const request = indexedDB.open(dbName, 1);

  //       request.onupgradeneeded = (event) => {
  //         const database = event.target.result;
  
         
  //           database.createObjectStore(areaName, { keyPath: 'id', autoIncrement: true });
       
        
  //           database.createObjectStore(zoneName, { keyPath: 'id', autoIncrement: true });
        
          
  //           database.createObjectStore(imageName, { keyPath: 'id', autoIncrement: true });
        
         
  //           database.createObjectStore(trialName, { keyPath: 'id', autoIncrement: true });
         
          
  //           database.createObjectStore(trialDetailsName, { keyPath: 'id', autoIncrement: true });
         
  //       };
  
  //       request.onsuccess = (event) => {
  //         const database = event.target.result;
  
  //         setDb(database);
  //         insertDataIntoarea(database,storedareaArray,areaName)
  //         insertDataIntoZone(database,storedzoneArray,zoneName)
  //         // deleteAllDataFromArea(database,areaName)
  //         // deleteAllDataFromZone(database,zoneName)
  //       };
  
  //       request.onerror = (event) => {
  //         console.error('Error opening IndexedDB', event);
  //       };
     
     
  //   }
  // }, [isOnline]);

  // useEffect(() => {
  //   if(isOnline){    
  //     deleteAllDataFromArea(db,areaName)
  //     deleteAllDataFromZone(db,zoneName)
  //   }
  //   }, [isOnline]);
  const insertDataIntoarea = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
  
    dataArray.forEach(data => {
      objectStore.add(data);
    });
  
    transaction.oncomplete = () => {
     
      setareaDataStatus(true)
    };
  
    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event);
    };
  };
  const insertDataIntoZone = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
  
    dataArray.forEach(data => {
      objectStore.add(data);
    });
  
    transaction.oncomplete = () => {
    };
  
    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event);
    };
  };

  const saveFileToDB = (file) => {
    
    const reader = new FileReader();

    reader.onload = (event) => {
      if (currenttrialData.id) {
        const fileData = event.target.result;
        const transaction = db.transaction(imageName, 'readwrite');
        const objectStore = transaction.objectStore(imageName);

        const newItem = {
          name: file.name,
          type: file.type,
          size: file.size,
          data: fileData, // Storing file data (ArrayBuffer) in IndexedDB
        };

        const request = objectStore.get(currenttrialData.image.id);

        request.onsuccess = (event) => {
          const fileToUpdate = event.target.result;

          if (fileToUpdate) {

            fileToUpdate.data = newItem; // Update the file data with the new value

            const updateRequest = objectStore.put({ name: file.name,
              type: file.type,
              size: file.size,
              data: fileData,
              id:currenttrialData.id,            
            });
            updateRequest.onsuccess = () => {
            };

            updateRequest.onerror = () => {
              console.error('Error updating file in IndexedDB');
            };
          } else {
            console.error('File not found in IndexedDB');
          }
        };

        request.onerror = () => {
        };
      }
      else {
        const fileData = event.target.result;

        const transaction = db.transaction(imageName, 'readwrite');
        const objectStore = transaction.objectStore(imageName);

        const newItem = {
          name: file.name,
          type: file.type,
          size: file.size,
          data: fileData, // Storing file data (ArrayBuffer) in IndexedDB
        };

        const request = objectStore.add(newItem);

        request.onsuccess = () => {
          const imgdata = request.result;
          setImgid(imgdata)
        };

        request.onerror = () => {
          console.error('Error saving image in IndexedDB');
        };
      }
    }


    reader.readAsArrayBuffer(file);
  };
  const deleteAllDataFromZone = (database, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    const deleteRequest = objectStore.clear();

    deleteRequest.onsuccess = () => {
     
    };

    deleteRequest.onerror = (event) => {
      console.error('Error deleting data from IndexedDB', event);
    };
  };
  const deleteAllDataFromArea = (database, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    const deleteRequest = objectStore.clear();

    deleteRequest.onsuccess = () => {
  
    };

    deleteRequest.onerror = (event) => {
      console.error('Error deleting data from IndexedDB', event);
    };
  };
  const ApiURL = configParam.API_URL;
  useEffect(() => {
    if (canvasRef.current) {
      // Set the canvas dimensions to the window's inner width and outer width
      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = window.outerHeight;
     
    }
  }, []);
  useEffect(() => {
    setSelectedDate(currentday)
    setRemarksValue(remarks[0])
  }, [open]);

  const handleCloudClick = () => {
    setCloudActive(true)
  }
  const handleCaptureCamera = async () => {
    setCaptureActive(true);
    // navigate('/CaptureImage');
    // if (fileInputRef.current && videoRef.current) {
    //   try {
    //     // Attempt to access the back camera
    //     const mediaStreamBack = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });

    //     // Set the camera stream to the video element
    //     videoRef.current.srcObject = mediaStreamBack;

    //     // Save the stream for later cleanup
    //     setStream(mediaStreamBack);

    //     videoRef.current.play();
    //   } catch (error) {
    //     console.error('Error accessing back camera:', error);

    //     try {
    //       // If accessing the back camera fails, try accessing the front camera
    //       const mediaStreamFront = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });

    //       // Set the camera stream to the video element
    //       videoRef.current.srcObject = mediaStreamFront;

    //       // Save the stream for later cleanup
    //       setStream(mediaStreamFront);

    //       videoRef.current.play();
    //     } catch (error) {
    //       console.error('Error accessing front camera:', error);
    //       // Handle the error as needed
    //     }
    //   }
    // }
  };


  // Add this function to stop the camera stream
  const stopCameraStream = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      setStream(null);
    }
  };

  const handleTakePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

      // Stop the camera stream
      stopCameraStream();

      // Convert the captured image to a data URL
      const imageData = canvasRef.current.toDataURL('image/png');

      // Handle the captured image data here
   

      // Process the captured image using the setUploadedFile function
      setUploadedFile({ target: { files: [dataURLtoFile(imageData)] } });
      setCaptureActive(false)
      setCloudActive(false)
    }
  };

  // Add this function to convert data URL to a file
  function dataURLtoFile(dataURL) {
    const arr = dataURL.split(',');
    const mimeRegex = /:([\w\/+-]+);/;
    const mimeMatch = arr[0].match(mimeRegex);
    const mime = mimeMatch ? mimeMatch[1] : 'application/octet-stream';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'captured-image.png', { type: mime });
  }


  const generateImagesArray = async (file) => {
    const imagesData = [];
  
    const reader = new FileReader();
    reader.onloadend = async () => {
      const imageType = file.type.split('/')[1];
      const imageBase64 = reader.result.split(',')[1];
      const imageName = `image_${Date.now()}.${imageType}`;
      imagesData.push({
        imageName,
        imageBase64,
        imageType,
      });
      setImagePath(`data:image/${imageType};base64,`+imageBase64);
      // Call the upload function after processing the images
    };
  
    reader.readAsDataURL(file);
  };

  const handleCaptureGallery = () => {
    // Open the gallery for image selection
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the input element to open the gallery
      fileInputRef.current.onchange = (e) => {

        const selectedFile = e.target.files[0];
  
        if(selectedFile){
          if (!selectedFile.type.startsWith("image/")) {
            setErrorMessageImage('please upload valid image')
          }
          else{
            setErrorMessageImage('')
            generateImagesArray(selectedFile);
        saveFileToDB(selectedFile)
          }
        }
       
      };
      setCaptureActive(false)
      setCloudActive(false)
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("user");
    let User = JSON.parse(data)
    setUserDet(User);
  }, []);
  const handleAreaChange = (event) => {
    setArea(event.target.value);
    getDescriptionDetails(event.target.value);
    getzoneDetails(event.target.value);
  };


  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleZoneChange = (event) => {
    setZone(event.target.value);;
  };
  const handleZoneUpdateChange = (event, newValue) => {
    setZone(newValue ? newValue.id : null);
  };
  const handleXrfNumberChange = (event) => {
  
    const inputValue = event.target.value;

    // Use a regular expression to check if the input is an integer
    if (/^\d*$/.test(inputValue)) {
      setXrfNumber(inputValue);
      setErrorMessageXrf('');
    } else {
      setErrorMessageXrf('Please enter only numeric values for XRF Reference Number.');
    }

  };

  const handleNiChange = (event) => {
    const inputValue = event.target.value;

    // Regular expression to allow only numbers and float values
    const regex = /^(?!^\.)([0-9]*(\.[0-9]*)?)?$/;

    if (regex.test(inputValue) || inputValue === '') {
      setNi(inputValue);
      setErrorMessageNi('');
    } else {
      setErrorMessageNi('Please enter only numbers and decimals.');
    }
   
  };

  const handleRemarksChange = (event) => {
    const selectedRemark = event.target.value;

    if (selectedRemark === 'Others' || selectedRemark === 'Not access') {
      setNi('0'); // Set Ni% to 0
      setXrfNumber('');
    }
  
  // Update the state variable for the selected remark
  setRemarksValue(selectedRemark);

  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const [addingNewZone, setAddingNewZone] = useState(false);
  const [newZoneValue, setNewZoneValue] = useState('');

  const handleAddNewZone = () => {
    const newtrial = { newZoneValue, area }
    if (newZoneValue) {
      axios
        .post(ApiURL + '/create_zone', newtrial) // Replace '/create_area' with your backend API endpoint to save the area data
        .then((response) => {
          getzoneDetails(area);
        })
        .catch((error) => {
          // Handle error
          console.error('Failed to save area:', error);
        });
      setAddingNewZone(false);
      setNewZoneValue('');

    }
  };

  const handleClose = () => {
    setArea(null);
    setDescription('');
    setZone('');
    setXrfNumber('');
    setNi('');
    setComments('');
    setImagePath('');
    setRemarksValue('');
    setSelectedDate(null);
    setErrorMessage('');
    setErrorMessageDescription('');
    setErrorMessageZone('');
    setErrorMessageNi('');
    setErrorMessageXrf('');
    setImageData('');
    setErrorMessageImage('');
    setErrorMessageComments('');
    setErrorMessageRemark('');
    setErrorMessageDate(null);
    stopCameraStream();
    setCaptureActive(false)
    setCloudActive(false)
    setOpenDialog(false)
    // resetFormFields();
    onclose();
  };

  const handleDateChange = (event) => {
    const dateTimeString = event.target.value; // Use default value if date is invalid
    const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    if (formattedDate > today) {
      setErrorMessageDate("You cannot select a future date.");
    } else {
      setErrorMessageDate('');
      setSelectedDate(formattedDate);
    }
  };


  const setUploadedFile = (event) => {
    if (currenttrialData.length > 0) {
      if (selectedRowData) {
        const parts = currenttrialData[0].path.split('/'); // Split the string by the backslash character
        const valuesAfterSlash = parts[1]
        deleteTrialImage(valuesAfterSlash);
      }
      const file = event.target.files[0];
      // setUploadedImage(file);
      if (file) {
        // If an image is uploaded, set the imagePath state to the URL of the uploaded image
        setImagePath(URL.createObjectURL(file));
        const form = new FormData();
        form.append('file', file, file.name);
        axios
          .post(ApiURL + '/upload_image', form) // Replace '/create_area' with your backend API endpoint to save the area data
          .then((response) => {
            const imgdata = response.data;
            setImageData(response.data);
            axios
              .post(ApiURL + `/update_image/${currenttrialData[0].img_id}`, imgdata) // Replace '/create_area' with your backend API endpoint to save the area data
              .then((response) => {
                setImageFilePath(response);

              })

              .catch((error) => {
                // Handle errors
                console.error('Failed to save area:', error);
              });
          })

          .catch((error) => {
            // Handle errors
            console.error('Failed to save area:', error);
          });

      } else {
        // If no image is uploaded, reset the imagePath state
        setImagePath('');
      }
    }
    else {
      const file = event.target.files[0];
      if (file) {
        // If an image is uploaded, set the imagePath state to the URL of the uploaded image
        setImagePath(URL.createObjectURL(file));
        const form = new FormData();
        form.append('file', file, file.name);
        axios
          .post(ApiURL + '/upload_image', form) // Replace '/create_area' with your backend API endpoint to save the area data
          .then((response) => {
            const imgdata = response.data
            setImageData(response.data);
            axios
              .post(ApiURL + '/create_imagepath', imgdata) // Replace '/create_area' with your backend API endpoint to save the area data
              .then((response) => {
                // Handle successful response
                setImageFilePath(response);
                getImageFileDetails(response);
              })
              // const imgName=imageData;
              // let url = ApiURL + `/show_file_name/${imgName}`;

              .catch((error) => {
                // Handle errors
                console.error('Failed to save area:', error);
              });
          })

          .catch((error) => {
            // Handle errors
            console.error('Failed to save area:', error);
          });

      } else {
        // If no image is uploaded, reset the imagePath state
        setImagePath('');
      }
    }
  };

  function generateUniqueUUID(existingUUIDs) {
    let newUUID;
    let isUnique = false;
  
    while (!isUnique) {
        newUUID = uuidv4(); // Generate a new UUID
        isUnique = !existingUUIDs.some(uuidObj => uuidObj.id === newUUID);
    }
  
    return newUUID;
  }

  const handleSubmit = () => {
    const idlist=JSON.parse(localStorage.getItem("trialdetailsallid"))
    const trial = {// Storing file data (ArrayBuffer) in IndexedDB
      area_id: area
    };
    const trialdetails = {
      xrfNumber: xrfNumber,
      Ni: Ni,
      remarksValue: remarksValue,
      comments: comments,
      date: selectedDate,
      img_id: imgid,
      name: userDet.id,
      tid:generateUniqueUUID(idlist)
    };
    const zonetable = {
      id: uuidv4(),
      zone: zone.inputValue,
      area_id: area,
      created_at: selectedDate,
    }
 
    if (
      !selectedDate ||
      !area ||
      !description ||
      (!zone && !inputValueZone) ||
      (!imagePath && !selectedRowData) ||
      (remarksValue !== 'Others' && remarksValue !== 'Not access' && !xrfNumber) ||
  (remarksValue !== 'Others' && remarksValue !== 'Not access' && !Ni)
  
    ) {
      if (!selectedDate) setErrorMessageDate('Please select a date.');
      if (!area) setErrorMessage('Please select an area.');
      if (!description) setErrorMessageDescription('Please enter a description.');
      if (!zone && !inputValueZone) setErrorMessageZone('Please select a zone.');
      if (!imagePath && !selectedRowData) setErrorMessageImage('Please add a image');
      if (remarksValue !== 'Others' && remarksValue !== 'Not access' && !xrfNumber) setErrorMessageXrf('Please enter an XRF reference number.');
      if (remarksValue !== 'Others' && remarksValue !== 'Not access' && !Ni) setErrorMessageNi('Please enter Ni%.');
      if (!remarksValue) setErrorMessageRemark('Please select a remark.');
      if (remarksValue !== 'Accessible' && !comments) setErrorMessageComments('Please enter comments.');
      if (remarksValue === 'Others' || remarksValue === 'Not access') {
        setErrorMessageXrf('');
        setErrorMessageNi('');
      }
     
      return;
    }


    if (currenttrialData.id) {
      const transaction = db.transaction(zoneName, 'readwrite');
      const objectStore = transaction.objectStore(zoneName);
      const objectStoreZone = transaction.objectStore(zoneName);
      const zonerequest = objectStore.get(currenttrialData.zone.id);
   
      const zonedetails = objectStoreZone.put({
        id: currenttrialData.zone.id,
        area_id: area,
        zone: inputValueZone.zone,
        created_at: selectedDate,
      });
      
      zonedetails.onsuccess = () => {
        
      }
      zonedetails.onerror = () => {
        console.error('Error fetching record from IndexedDB for update');
      };

      const transactiontrial = db.transaction(trialName, 'readwrite');
      const objectStoretrial = transactiontrial.objectStore(trialName);

      // Fetch the record you want to update using its ID
      const trialRequest = objectStoretrial.get(currenttrialData.trial_id);
      
      trialRequest.onsuccess = (event) => {
        const recordToUpdate = event.target.result;
       
        if (recordToUpdate) {
          // Update the record with the new data
          const trialUpdatetable = {// Storing file data (ArrayBuffer) in IndexedDB
            area_id: area
          };
          recordToUpdate.area_id = area; // Assuming 'data' is the field you want to update
        
          // Put the updated record back into the object store
          // const updatetrialRequest = objectStoretrial.put(recordToUpdate);

          // updatetrialRequest.onsuccess = () => {
          //   console.log('Record updated successfully in trial');
          // };

          // updatetrialRequest.onerror = () => {
          //   console.error('Error updating record in IndexedDB');
          // };
        } else {
          console.error('Record not found in IndexedDB');
        }
        const transactiontrialdetail = db.transaction(trialDetailsName, 'readwrite');
        const objectStoretrialdetail = transactiontrialdetail.objectStore(trialDetailsName);
        const trialDetailsRequest = objectStoretrialdetail.put({
          id:currenttrialData.id,
          xrfNumber: xrfNumber,
          Ni: Ni,
          remarksValue: remarksValue,
          comments: comments,
          date: selectedDate,
          img_id: currenttrialData.image.id,
          name: userDet.id,
          trial_id: currenttrialData.trial_id,
          zone_id: inputValueZone.id
        });

        trialDetailsRequest.onsuccess = () => {
        
          onShowAlertMessage('inspection updated successfully')
          onHandleTrialDetails();
        };

        trialDetailsRequest.onerror = () => {
          console.error('Error fetching record from IndexedDB for update');
        };
      };

      trialRequest.onerror = () => {
        console.error('Error fetching record from IndexedDB for update');
      };



    }
    else{
      if (zone.id) {
        const transaction = db.transaction(trialName, 'readwrite');
        const objectStore = transaction.objectStore(trialName);
  
        
        //  const trialrequest = objectStore.add(trial);
        const trialrequest = objectStore.add(trial);
  
        trialrequest.onsuccess = () => {
          const trialid = trialrequest.result;
          trialdetails.trial_id = trialid;
          trialdetails.zone_id = zone.id;
         
          const transaction = db.transaction(trialDetailsName, 'readwrite');
          const objectStore = transaction.objectStore(trialDetailsName);
          const trialdetailsrequest = objectStore.add(trialdetails);
          trialdetailsrequest.onsuccess = () => {
           
            onShowAlertMessage('inspection created successfully')
            onHandleTrialDetails();
          };
  
          trialdetailsrequest.onerror = () => {
          
          };
        };
  
        trialrequest.onerror = () => {
         
        };
      }
      else {
        const transaction = db.transaction(zoneName, 'readwrite');
        const objectStore = transaction.objectStore(zoneName);
  
  
        const zonerequest = objectStore.add(zonetable);
  
        zonerequest.onsuccess = () => {
          const zoneid = zonerequest.result;
          trialdetails.zone_id = zoneid;
         
          const transaction = db.transaction(trialName, 'readwrite');
          const objectStore = transaction.objectStore(trialName);
          const trialrequest = objectStore.add(trial);
          trialrequest.onsuccess = () => {
            const trialid = trialrequest.result;
            trialdetails.trial_id = trialid;
            
            const transaction = db.transaction(trialDetailsName, 'readwrite');
            const objectStore = transaction.objectStore(trialDetailsName);
            const trialdetailsrequest = objectStore.add(trialdetails);
            trialdetailsrequest.onsuccess = () => {
              
              onShowAlertMessage('inspection created successfully')
              onHandleTrialDetails();
            };
  
            trialdetailsrequest.onerror = () => {
            };
          };
  
          trialrequest.onerror = () => {
         
          };
        };
  
        zonerequest.onerror = () => {
         
        };
      }
    }
    
    setArea(null);
    setDescription('');
    setInputValueZone(null);
    setXrfNumber('');
    setNi('');
    setZone('');
    setComments('');
    setRemarksValue('');
    setSelectedDate(null);
    setImageData('');
    setImagePath('');
    onclose();
    setErrorMessage('');
    setErrorMessageDescription('');
    setErrorMessageZone('');
    setErrorMessageXrf('');
    setErrorMessageNi('');
    setErrorMessageRemark('');
    setErrorMessageComments('');
    setErrorMessageImage('');
    setErrorMessageDate(null);
  }
  useEffect(() => {
    if (!isOnline) {
      const fetchData = async () => {
        if (currenttrialData) {
         
          const selectedArea = areaData.find((option) => option.area === currenttrialData.area.area);
          setArea(selectedArea ? selectedArea.id : null);
          setDescription(selectedArea?selectedArea.description:null)
          if (selectedArea) {
            try {
              await getzoneDetails(selectedArea.id);
              const selectedZone = zoneData.find((option) => option.zone === currenttrialData.zone.zone);
              setInputValueZone(selectedZone || null);
              if (!isInputValueZoneInitialized) {
                setInputValueZoneInitialized(true);
              }

            } catch (error) {
              // Handle errors if needed
              console.error("Error fetching zone details:", error);
            }
          };

          const xrfNumber = currenttrialData?.xrfNumber || ''; // Ensure xrfNumber is defined
          const ni = currenttrialData?.Ni || '';
          const comments = currenttrialData?.comments || '';

          // Convert date string to Date object
          const dateTimeString = currenttrialData?.date || ''; // Use default value if date is invalid
          const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
          const increasedDate = moment(formattedDate, "YYYY-MM-DD").add(1, 'days');

          // Format the increased date back to the desired format
          const formattedIncreasedDate = increasedDate.format("YYYY-MM-DD");
          setSelectedDate(dateTimeString);

          // Set the state for xrfNumber, ni, and comments
          setXrfNumber(xrfNumber);
          setNi(ni);
          setComments(comments);
          const selectedRemarks = currenttrialData?.remarksValue || '';
          setRemarksValue(selectedRemarks);
        } else {
          // If selectedRowData is null, it's an add operation

          setArea(null);
          setInputValueZone(null);
          setXrfNumber('');
          setNi('');
          setComments('');
          setRemarksValue('');
          setSelectedDate(null);
        }
      };
      fetchData();
    }

  }, [currenttrialData, isInputValueZoneInitialized, isOnline]);

  useEffect(() => {
    if (isInputValueZoneInitialized) {
  
      // Additional logic or state updates that depend on inputValuePlant
    }
  }, [inputValueZone, isInputValueZoneInitialized]);

  // useEffect(() => {
  //   getimageDetails();

  //   // getImageFileDetails(imageData);

  // }, []);

  // const getimageDetails = () => {
  //   let url = ApiURL + '/show_image';
  //   axios.get(url).then((response) => {
  //     setImageFileData(response.data);
  //   }).catch((error) => {
  //     setImageFileData([]);
  //   });
  // };
  const deleteTrialImage = (imageName) => {
    const url = ApiURL + `/delete_image/${encodeURIComponent(imageName)}`; // Replace with your backend API endpoint to delete area
    axios
      .delete(url)
      .then((response) => {

      })
      .catch((error) => {
        // Handle error
        console.error('Failed to delete image:', error);
      });
  };
  useEffect(() => {
    if(db){
      getareaDetails();
    }
     
  }, [db]);
  // useEffect(() => {

  //   open()
  // }, [navigate]);

  const getareaDetails = () => {
 
   
      const transaction = db.transaction(areaName, 'readonly');
      const objectStore = transaction.objectStore(areaName);

      const getAllRequest = objectStore.getAll();
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result;
        const filteredArray = allData.filter(item => item.plant_id === userlogoutdata);
        setAreaData(filteredArray)
      
        // Process the 'allData' array as needed (e.g., display, manipulate, etc.)
      };

      getAllRequest.onerror = (event) => {
        setAreaData([])
        console.error('Error getting data from the area table in IndexedDB', event);
      };
    
  };

  const getzoneDetails = (areazone) => {
    const zoneId = areazone
    
      // This event is triggered when the database connection is successfully opened
    
      const transaction = db.transaction(zoneName, 'readonly');
      const objectStore = transaction.objectStore(zoneName);
      // const index = objectStore.index('area_id'); // Assuming 'area_id' is the index name
      const getAllRequest = objectStore.getAll();
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result;
        const filteredRecords = allData.filter(record => record.area_id === zoneId);
        setZoneData(filteredRecords)
       
        // Process the 'allData' array as needed (e.g., display, manipulate, etc.)
      };

      getAllRequest.onerror = (event) => {
        setZoneData([])
        console.error('Error getting data from the area table in IndexedDB', event);
      };

  };
  const getzoneDetailsUpdate = async (area) => {
    const zoneId = area;
    const url = ApiURL + `/show_zone/${zoneId}`;

    try {
      const response = await axios.get(url);
      setZoneData(response.data);
      return response.data; // Return the fetched data
    } catch (error) {
      setZoneData([]);
      throw error; // Re-throw the error to handle it outside the function
    }
  };

  const getImageFileDetails = (response) => {
    const imgName = response.data.identifiers[0].id;
    let url = ApiURL + `/show_file_name/${imgName}`;
    axios.get(url).then((response) => {
      setFileData(response.data[0].id);
    }).catch((error) => {
      setFileData([]);
    });

  };
  const getDescriptionDetails = (areazone) => {
    const zoneId = areazone
    const request = indexedDB.open(dbName, 1);
  
    request.onsuccess = (event) => {
      // This event is triggered when the database connection is successfully opened
      const database = event.target.result;
     
      const transaction = database.transaction(areaName, 'readonly');
      const objectStore = transaction.objectStore(areaName);
      // const index = objectStore.index('area_id'); // Assuming 'area_id' is the index name
      const getAllRequest = objectStore.getAll();
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result;
        const filteredRecords = allData.filter(record => record.id === zoneId);
        const descriptions = filteredRecords.map(record => record.description);
        setDescription(descriptions)
       
        // Process the 'allData' array as needed (e.g., display, manipulate, etc.)
      };

      getAllRequest.onerror = (event) => {
        setDescription([])
        console.error('Error getting data from the area table in IndexedDB', event);
      };
    };

    request.onerror = (event) => {
      // This event is triggered if there's an error during the database open operation
      console.error('Error opening IndexedDB', event);
    };
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValueZone({ id: inputValueZone?.id || null, zone: newInputValue });
    setInputZone(newInputValue);
  };
  const renderZoneField = () => {
    if (selectedRowData) {
      return (
        <div>
          <Autocomplete
            className="autocomplete-nis"
            size="small"
            value={inputValueZone}
            onChange={(event, newValue) => {
              setInputValueZone(newValue);
              setInputZone(newValue ? newValue.zone : ''); // Clear the input when an option is selected
            }}
            inputValue={inputZone}
            onInputChange={handleInputChange}
            options={zoneData}
            getOptionLabel={(option) => option.zone}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type something"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
      )
    }
    else {
      return (
        <div>
          <Autocomplete
            className="autocomplete-nis"
            size="small"
            value={zone}
            onChange={(event, newValue) => {
              setZone(newValue);
            }}

            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest creating a new option if the user input doesn't match any options
              if (params.inputValue !== '' && !filtered.length) {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="my-autocomplete"
            options={zoneData}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.zone;
            }}
            renderOption={(props, option) => (
              <li {...props}>
                {option.inputValue ? `Add "${option.inputValue}"` : option.zone}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select or Add Option"
                variant="outlined"
                fullWidth
              />
            )}
            style={{ marginBottom: 16 }}
          />
        </div>)
    }
  };
  const today = new Date().toISOString().split('T')[0];

  const handleSubmissionInCapture = () => {
    setCaptureActive(false);
    setCloudActive(false)
    setOpenDialog(true); // Set this to true if you want to open the dialog
    // navigate('/view'); // Navigate back to the view
  };
  return (

    <Dialog open={open || openDialog} onClose={handleClose} fullWidth maxWidth="xs">
      {/* <div style={modalContentStyle}> */}
      {currenttrialData.length>0?(
        <DialogTitle style={{ paddingBottom: 0 }}>Edit Inspection Offline</DialogTitle>
      ):(
<DialogTitle style={{ paddingBottom: 0 }}>New Inspection Offline</DialogTitle>
      )}
      {/* <Typography variant="h6" style={modalTypographyStyle}>
        New Trail
      </Typography> */}
      <DialogContent className={`dialog-content ${isCursorInsideDialog ? 'show-scroll' : ''}`}



        onMouseEnter={() => {

          setIsCursorInsideDialog(true);

        }}

        onMouseLeave={() => {

          setIsCursorInsideDialog(false);

        }}>
        {captureActive ? (
          <CaptureImage handleSubmission={handleSubmissionInCapture} setUploadedFile={setUploadedFile} dataURLtoFile={dataURLtoFile} />
        ) : (<div>
          {cloudActive && !captureActive && (
            <div>
              <canvas
                ref={canvasRef}
                style={{ display: 'none' }}
              />
              <video
                ref={videoRef}
                style={{ width: '100%', height: 'auto' }}
                autoPlay
                playsInline
              />
              <input  id="upload-image" multiple type="file" style={{ display: 'none' }} onChange={setUploadedFile} ref={fileInputRef} />
              {/* Button to select from gallery */}
              <button onClick={handleCaptureGallery}>Select from Gallery</button>
            </div>

          )}

          {/* {errorMessage && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessage}</p>} */}
          {!cloudActive && !captureActive && (
            <div>
              <div>
                {errorMessageImage && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageImage}</p>}
                <IconButton color="primary" component="span" onClick={handleCloudClick}>
                  <CloudUploadIcon />
                </IconButton>
                {(selectedRowData || imageData) && (
                  <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                )}
              </div>
              <div>
                {(imagePath) && (
                  <img
                    src={imagePath}
                    alt="Selected Image"
                    style={imageStyle}
                  />
                )}

              </div>
            </div>
          )}
          <InputLabel style={modalInputLabelStyle}>

            Date

          </InputLabel>
          <div style={{ marginBottom: "16px" }}>
            {errorMessageDate && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageDate}</p>}
            <TextField
              size="small" className="input-nis"
              fullWidth
              id="date"
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              inputProps={{ max: today }}
            />
            {errorMessage && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessage}</p>}
          </div>

          <div style={{ marginBottom: "16px" }}></div>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="area-label" shrink={Boolean(area)} style={modalInputLabelStyle}>Area</InputLabel>
            <Select labelId="area-label" className='select-nis' size="small" value={area} onChange={handleAreaChange}>
              {areaData.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.area}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel style={modalInputLabelStyle}>
            Description
          </InputLabel>
          {errorMessageDescription && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageDescription}</p>}
          <TextField size="small" className="input-nis"
            value={description}
            multiline
            onChange={handleDescriptionChange}
            fullWidth
            style={{ marginBottom: 16, border: '1px solid #ccc', borderRadius: 4, padding: 8 }}
            disabled={true}
          />
          <InputLabel style={modalInputLabelStyle}>Zone</InputLabel>
          {errorMessageZone && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageZone}</p>}
          {renderZoneField()}
          <InputLabel style={modalInputLabelStyle}>
            XRF Reference Number
          </InputLabel>
          {errorMessageXrf && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageXrf}</p>}
          <TextField size="small" className="input-nis"
            value={xrfNumber}
            onChange={handleXrfNumberChange}
            fullWidth
            style={{ marginBottom: 16 }}
            disabled={remarksValue === 'Others' || remarksValue === 'Not access'}
          />
          <InputLabel style={modalInputLabelStyle}>
            Ni%
          </InputLabel>
          {errorMessageNi && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageNi}</p>}
          <TextField size="small" className="input-nis"
            value={Ni}
            onChange={handleNiChange}
            fullWidth
            style={{ marginBottom: 16 }}
            disabled={remarksValue === 'Others' || remarksValue === 'Not access'}
          />
          <InputLabel id="remarks-label" style={modalInputLabelStyle}>Remark</InputLabel>
          {errorMessageRemark && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageRemark}</p>}
          <FormControl fullWidth>

            <Select
              labelId="remarks-label"
              value={remarksValue}
              size="small"
              onChange={handleRemarksChange}
              fullWidth
              className='select-nis'
              style={{ marginBottom: 16 }}
            >
              <MenuItem value="">Select a remark</MenuItem>
              {remarks.map((remark, index) => (
                <MenuItem key={index} value={remark}>
                  {remark}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <>
            <InputLabel style={modalInputLabelStyle}>
              Comments
            </InputLabel>
            {errorMessageComments && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageComments}</p>}
            <TextField
              size="small"
              className="input-nis"
              value={comments}
              onChange={handleCommentsChange}
              fullWidth
              style={{ marginBottom: 16 }}
            />
          </>
          <DialogActions style={{ borderTop: '1px solid #f5f5f5', padding: '10px 20px' }}>
            <Button variant="contained" onClick={handleClose} style={{ marginRight: 10, backgroundColor: '#002060' }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} style={{ backgroundColor: '#002060' }}>
              Submit
            </Button>
          </DialogActions>
        </div>
        )}


      </DialogContent>


    </Dialog>
  );
};

export default AddTrialOffline;