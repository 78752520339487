import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import axios from 'axios';
import configParam from '../../config';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from "recoil";
import { processPlantId } from '../../recoil/atoms';
import { userProcessPlantState } from '../../recoil/atoms';

const ApiURL = configParam.API_URL;



function ProcessingPlantCards() {
  const [PlantId, setPlantId] = useRecoilState(processPlantId);


  const a = useParams();

  const processplantId = a.processPlantId;
  const [, setPlantname] = useRecoilState(userProcessPlantState);
  const [plants, setPlants] = useState([]);
  const [plantData, setPlantData] = useState({}); // Store plant data (count and averageNi)
  const [, setUserDet] = useState({});
  const navigate = useNavigate();


  useEffect(() => {
    let data = localStorage.getItem('user');
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);

  useEffect(() => {
    const P_Data = async (plantName) => {
      try {
        const plant_Name = plantName;
        const response = await axios.get(ApiURL + `/processing_plant/${plant_Name}`);
        const data = response.data;
       
        return data;
      } catch (error) {
        console.error('Error fetching plant data:', error);
        return { count: 0, averageNi: 0 }; // Handle error gracefully
      }
    };

    const fetchPlants = async () => {
    
      try {
      
        const response = await axios.get(ApiURL + `/processing_plant_cards/${processplantId}`);
        const data = response.data;
       
        setPlants(data);

        const plantDataPromises = data.map((plant) => P_Data(plant.id));
        const plantDataArray = await Promise.all(plantDataPromises);

        const plantDataObject = data.reduce((acc, plant, index) => {
          acc[plant.id] = plantDataArray[index];
          return acc;
        }, {});

        setPlantData(plantDataObject);
      } catch (error) {
        console.error('Error fetching plants:', error);
      }
    };

    fetchPlants();
  }, [processplantId]);

  const handlePlantCardClick = (id) => {
    const url = `/analytics/${id}`;
    setPlantId(id);

    navigate(url);
  };
  useEffect(() => {
    if (PlantId) {
      localStorage.setItem('user_process_id', PlantId);
      const plant = plants.find(plant => plant.id === PlantId);

      // Check if a matching plant was found and retrieve its name
      if (plant) {
        const plantName = plant.processing_plant_name;
        localStorage.setItem('user_process_plant', plantName);
        setPlantname(plantName);
      }
    }
  }, [PlantId, plants]);

  function formatBusinessName(name) {
    const trimmedName = name.trim(); // Remove extra spaces
    if (trimmedName.length <= 3) {
      return trimmedName.toUpperCase();
    } else {
      return trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1).toLowerCase();
    }
  }
  return (
    <Grid
      container
      className="plant-container"
      spacing={2}
      style={{ marginTop: '20px' }}
    >
      {plants.map((plant) => (
        <Grid item key={plant.plantName} xs={12} sm={6} md={4} lg={3}>
          <Link to={`/analytics/${plant.id}`}>
            <Card
              sx={{
                backgroundColor: '#002060',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                color: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',


              }}
              onClick={() => {
                handlePlantCardClick(plant.id);
              }}
            >
              <CardContent>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div>
                    <Typography style={{ fontSize: "16px", fontWeight: 500, color: '#FFFFFF' }}>
                      {formatBusinessName(plant.processing_plant_name)}

                    </Typography>

                    <Typography style={{ fontWeight: 600, fontSize: "36px", color: '#FFFFFF' }}>
                      {plantData[plant.id] ? plantData[plant.id].count : 'Loading...'}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      marginTop: "30px",
                    }}
                  >
                    <Typography style={{ fontWeight: 400, fontSize: "14px", color: '#FFFFFF' }}>
                      Average Nickel %:
                    </Typography>
                    <Typography style={{ fontWeight: 400, fontSize: "14px", color: '#FFFFFF' }}>
                      {plantData[plant.id] ? plantData[plant.id].averageNi : 'Loading...'}
                    </Typography>
                  </div>
                </div>
              </CardContent>

            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}




export default ProcessingPlantCards;