import React, { useState,useEffect, useRef} from 'react';

import { useRecoilState } from "recoil";

import configParam from '../../config';
import { IconButton} from '@mui/material';

import { plantId } from '../../recoil/atoms';
import {
  
  Button,
 
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Swal from 'sweetalert2';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios";
import CaptureImage from './CaptureImage';




import { useMediaQuery } from '@mui/material';
const moment = require('moment');

const remarks = ['Accessible','Not access', 'Not in contact', 'Others'];
const filter = createFilterOptions();



const modalInputLabelStyle = {
 
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#000',
};
const imageStyle = {
  width: '100%', // Set the width to 100% of its container
  height: 'auto',  // Let the height adjust to maintain the aspect ratio
  display: 'block', // Remove any extra spacing or borders
};
const currentday = new Date().toISOString().split('T')[0];

const access_token=localStorage.getItem("accesstoken");

const AddTrial = ({open,onclose,selectedRowData,currenttrialData,onHandleTrial,onHandleTrialDetails,onSubmit,resetFormFields,onShowAlertMessage,onHandleTrialAreaDetailss,onHandleTrialYearDetailss,onHandletrialDetailss,onHandletrialTableDetailss,onHandleTrialAreaDateDetailss,onHandleTrialPlantDateDetailss,onHandletrialYearss,selectedYears,onHandleTrialTablePlantDetailss,onHandletrialsDetailss,setTrialDatas,setTrialTableDatas,setYearsOptions,trialDatas}) => {

  const [area, setArea] = useState(null);
  const [description, setDescription] = useState('');
  const [zone, setZone] = useState('');
  const [xrfNumber, setXrfNumber] = useState('');
  const [Ni, setNi] = useState('');
  const [remarksValue, setRemarksValue] = useState('');
  const [loading, setLoading] =useState(false);
  const [comments, setComments] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const datauser=localStorage.getItem("user_id")
  let data = localStorage.getItem('user');
  let User = JSON.parse(data);
  const userlogoutdata = datauser!=null?datauser:User.plant_id[0];

  const [zoneData, setZoneData] = useState([]);
  const [, setDescriptionData] = useState([]);
 
  const [selectedDate, setSelectedDate] = useState(null); 
  const [imagePath, setImagePath] = useState('');
  const [,setImageFilePath]=useState('');
  

  const [imageData,setImageData]=useState('');
  const [,setImageFileData]=useState('');
  const [fileData,setFileData]=useState('');
 const [imgfile,setimgfile]=useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageImage,setErrorMessageImage]=useState('');
  const [errorMessageDescription, setErrorMessageDescription] = useState('');
  const [errorMessageZone, setErrorMessageZone] = useState('');
  const [errorMessageXrf, setErrorMessageXrf] = useState('');
  const [errorMessageNi, setErrorMessageNi] = useState('');
  const [errorMessageRemark, setErrorMessageRemark] = useState('');
  const [errorMessageComments, setErrorMessageComments] = useState('');
  const [errorMessageDate, setErrorMessageDate] = useState(null);
  const [inputValueZone,setInputValueZone]=useState('');
  const [inputZone,setInputZone]=useState(null);
  const [isInputValueZoneInitialized, setInputValueZoneInitialized] = useState(false);
  // const [selectedYear, setSelectedYear] = useState('');
  const [PlantId, _] = useRecoilState(plantId);
  const [userDet, setUserDet] = useState({});
  const [isCursorInsideDialog, setIsCursorInsideDialog] = useState(false);
  const [cloudActive, setCloudActive] = useState(false);
  const [captureActive, setCaptureActive] = useState(false);
  
  const isMobileView = useMediaQuery('(max-width:600px)');
  const ApiURL = configParam.API_URL;

  useEffect(() => {
    if (canvasRef.current) {
      // Set the canvas dimensions to the window's inner width and outer width
      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = window.outerHeight;
     
    }
  }, []);
  useEffect(() => {
setSelectedDate(currentday)  
setRemarksValue(remarks[0])
  }, [open]);

  const handleCloudClick=()=>{
    setCloudActive(true)
  }


  // Add this function to stop the camera stream
const stopCameraStream = () => {
  if (stream) {
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    setStream(null);
  }
};

const handleTakePicture = () => {
  if (videoRef.current && canvasRef.current) {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

    // Stop the camera stream
    stopCameraStream();

    // Convert the captured image to a data URL
    const imageData = canvasRef.current.toDataURL('image/png');

    // Handle the captured image data here
    

    // Process the captured image using the setUploadedFile function
    setUploadedFile({ target: { files: [dataURLtoFile(imageData)] } });
    setCaptureActive(false)
    setCloudActive(false)
  }
};

// Add this function to convert data URL to a file
function dataURLtoFile(dataURL) {
  const arr = dataURL.split(',');
  const mimeRegex = /:([\w\/+-]+);/;
  const mimeMatch = arr[0].match(mimeRegex);
  const mime = mimeMatch ? mimeMatch[1] : 'application/octet-stream';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], 'captured-image.png', { type: mime });
}

const handleCaptureGallery = () => {
  // Open the gallery for image selection
  if (fileInputRef.current) {
    fileInputRef.current.click(); // Trigger the input element to open the gallery
    fileInputRef.current.onchange = (e) => {
      const selectedFile = e.target.files[0];
      
      if (selectedFile) {
        // Process the selected image using the setUploadedFile function
        if (!selectedFile.type.startsWith("image/")) {
          setErrorMessageImage('please upload valid image')
        }
        else{
          setErrorMessageImage('')
          setUploadedFile({ target: { files: [selectedFile] } });
        }
        
      }
    };
    setCaptureActive(false);
    setCloudActive(false);
  }
};

const handleCaptureCamera = () => {
  // Open the camera for image capture
  if (fileInputRef.current) {
    fileInputRef.current.click(); // Trigger the input element to open the camera
    fileInputRef.current.onchange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        // Process the selected image using the setUploadedFile function
        setUploadedFile({ target: { files: [selectedFile] } });
      }
    };
    setCaptureActive(false);
    setCloudActive(false);
  }
};





  useEffect(()=>{
    let data = localStorage.getItem("user");
    let User = JSON.parse(data)
    setUserDet(User);
  },[]);
  useEffect(() => {
    const selectedOption = areaData.find((option) => option.id === area);
    if (selectedOption) {
      getDescriptionDetails(selectedOption.id)
        .then((data) => {
          setDescription(data[0]?.description);
        });
    } else {
      setDescription('');
    }
  }, [area]);
  const handleAreaChange = (event) => {
    setArea(event.target.value); 
    getzoneDetails(event.target.value);
  };
  

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  
  const handleXrfNumberChange = (event) => {
  
    const inputValue = event.target.value;

    // Use a regular expression to check if the input is an integer
    if (/^\d*$/.test(inputValue)) {
      setXrfNumber(inputValue);
      setErrorMessageXrf('');
    } else {
      setErrorMessageXrf('Please enter only numeric values for XRF Reference Number.');
    }
  };

  const handleNiChange = (event) => {
    const inputValue = event.target.value;

    // Regular expression to allow only numbers and float values
    const regex = /^(?!^\.)([0-9]*(\.[0-9]*)?)?$/;

    if (regex.test(inputValue) || inputValue === '') {
      setNi(inputValue);
      setErrorMessageNi('');
    } else {
      setErrorMessageNi('Please enter only numbers and decimals.');
    }
   
  };

  const handleRemarksChange = (event) => {
    const selectedRemark = event.target.value;

    if (selectedRemark === 'Others' || selectedRemark === 'Not access') {
      setNi('0'); // Set Ni% to 0
      setXrfNumber('');
    }
  
  // Update the state variable for the selected remark
  setRemarksValue(selectedRemark);

  };

 

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const [, setAddingNewZone] = useState(false);
  const [newZoneValue, setNewZoneValue] = useState('');

  const handleAddNewZone = () => {
    const newtrial={newZoneValue,area}
    if (newZoneValue) {
      axios
      .post(ApiURL + '/create_zone', newtrial,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
) // Replace '/create_area' with your backend API endpoint to save the area data
      .then((response) => {
        getzoneDetails(area);
      })
      .catch((error) => {
        // Handle error
        console.error('Failed to save area:', error);
      });
      setAddingNewZone(false);
      setNewZoneValue('');
      
    }
  };

  const handleClose = () => {
    setArea(null);
    setDescription('');
    setZone('');
    setXrfNumber('');
    setNi('');
    setComments('');
    setImagePath('');
    setRemarksValue('');
    setSelectedDate(null);
    setErrorMessage('');
    setErrorMessageDescription('');
    setErrorMessageZone('');
    setErrorMessageNi('');
    setErrorMessageXrf('');
    setImageData('');
    setErrorMessageImage('');
    setErrorMessageComments('');
    setErrorMessageRemark('');
    setErrorMessageDate(null);
    stopCameraStream();
    setCaptureActive(false)
    setCloudActive(false)
    setOpenDialog(false)

    onclose();
  };

  const handleDateChange = (event) => {
    const dateTimeString = event.target.value; // Use default value if date is invalid
   const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
   const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

   if (formattedDate > today) {
     setErrorMessageDate("You cannot select a future date.");
   } else {
     setErrorMessageDate('');
     setSelectedDate(formattedDate);
   }
  };
  const setUploadedFile = async (event) => {
    const file = event.target.files[0];
    
    if (file) {
      // If an image is uploaded, set the imagePath state to the URL of the uploaded image
     
      const imageData = await generateImagesArray(file);
      setimgfile(file);
      // await uploadBlobImagesAPI(imageData,file);
    } else {
      // If no image is uploaded, reset the imagePath state
      setImagePath('');
    }
  };
  


  const uploadBlobImagesAPI = async (images) => {
   
    
    if (currenttrialData.length > 0) {
      try {
        const url = `${ApiURL}/upload_image_to_blob`;
    
        // Set a timeout of 80 seconds (80,000 ms)
        const response = await axios.post(url, { images }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          timeout: 90000  // Timeout after 80 seconds (80,000 ms)
        });
    
        // Ensure response data is valid before proceeding
        if (response.status === 401) throw new Error('Unauthorized');
        
        if (response.data && response.data[0] && response.data[0].name && response.status === 200) {
          const imgresponse = await axios.post(
            ApiURL + `/update_image/${currenttrialData[0].img_id}/${response.data[0].name}`, {}, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
              timeout: 90000  // Timeout after 80 seconds (80,000 ms)
            }
          );
    
          // Log the response for debugging
          if (imgresponse.status===200 && response.data[0].name ) {
            const imgname = await getImageFileDetails(currenttrialData[0].img_id);
            return imgname;
          }
        }
      } catch (error) {
        console.error('Error uploading images:', error);
        setArea(null);
    setDescription('');
    setZone('');
    setXrfNumber('');
    setNi('');
    setComments('');
    setRemarksValue('');
    setImageData('');
    setImagePath('');
    setSelectedDate(null);
  onclose();
  setErrorMessage('');
setErrorMessageDescription('');
setErrorMessageZone('');
setErrorMessageXrf('');
setErrorMessageNi('');
setErrorMessageRemark('');
setErrorMessageComments('');
setErrorMessageImage('');
setErrorMessageDate(null);
setLoading(false);
        // Specific handling for timeout error
        if (error.code === 'ECONNABORTED') {
          Swal.fire({
            icon: 'warning',
            title: 'Timeout Error',
            text: 'The image upload request timed out after 80 seconds. Please try again.',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Submission Error',
            text: 'There was an error submitting your data.',
          });
        }
      }
    }
    else {
      try {
        const url = `${ApiURL}/upload_image_to_blob`;
        const response = await axios.post(url, { images }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
           
          },
          timeout: 90000  // Timeout after 80 seconds (80,000 ms)
        });
  
        
  
        // Ensure response data is valid before proceeding
        if (response.data && response.data[0] && response.data[0].name) {
          const imgresponse = await axios.post(
            ApiURL + `/create_imagepath/${response.data[0].name}`, {}, {
              headers: {
                Authorization: `Bearer ${access_token}`,
               
              },
              timeout: 90000  // Timeout after 80 seconds (80,000 ms)
            }
          );
  
          // Log the response for debugging
        
  
          if(imgresponse.status===200 && response.data[0].name ){
            const imgname = await getImageFileDetails(imgresponse.data.identifiers[0].id);
            return imgname;
          }
        }
      } catch (error) {
        console.error('Error uploading images:', error);
        setArea(null);
    setDescription('');
    setZone('');
    setXrfNumber('');
    setNi('');
    setComments('');
    setRemarksValue('');
    setImageData('');
    setImagePath('');
    setSelectedDate(null);
  onclose();
  setErrorMessage('');
setErrorMessageDescription('');
setErrorMessageZone('');
setErrorMessageXrf('');
setErrorMessageNi('');
setErrorMessageRemark('');
setErrorMessageComments('');
setErrorMessageImage('');
setErrorMessageDate(null);
setLoading(false);
        // Specific handling for timeout error
        if (error.code === 'ECONNABORTED') {
          Swal.fire({
            icon: 'warning',
            title: 'Timeout Error',
            text: 'The image upload request timed out after 80 seconds. Please try again.',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Submission Error',
            text: 'There was an error submitting your data.',
          });
        }
      }
    }
  };
  
  const generateImagesArray = async (file) => {
    return new Promise((resolve, reject) => {
      const imagesData = [];
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const imageType = file.type.split('/')[1];
        const imageBase64 = reader.result.split(',')[1];
        const imageName = `image_${Date.now()}.${imageType}`;
      
        imagesData.push({
          imageName,
          imageBase64,
          imageType,
        });
        setImagePath(`data:image/${imageType};base64,` + imageBase64);
        resolve(imagesData); // Resolve the promise with the imagesData array
      };
  
      reader.onerror = (error) => {
        reject(error); // Reject the promise if an error occurs
      };
  
      reader.readAsDataURL(file);
    });
  };
  

const getTrialPlantDateDetails = (plants,Year) => {
  const plantId = plants;
  const year = Year;
  let url = ApiURL + `/show_trial_details_plant/${plantId}/${year}`;
  axios.get(url,{
    headers: {
      Authorization: `Bearer ${access_token}`,
     
    }
  }
).then((response) => {
    const newData = [...response.data];
    onSubmit(newData);
  }).catch((error) => {
    setTrialDatas([]);
  });
};



const getTrialAreaDateDetails = (areas, Year, newTrail) => {
  const areaId = areas;
  const year = Year;
  if (userlogoutdata) {
    let url = ApiURL + `/show_trial_details_area/${areaId}/${year}/${userlogoutdata}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      // Append newTrail to the response data
      const newData = [...response.data];
      // Call the callback function with the updated data
      onSubmit(newData);
      // setTrialDatas(newData);
     
    }).catch((error) => {
      // Call the callback function with an empty array in case of error
      setTrialDatas([]);
    });
  } 
};





const gettrialDetails = () => {
  if (userlogoutdata) {
    let url = ApiURL + `/show_trial_details/${userlogoutdata}`;
    axios.get(url).then((response) => {
      setTrialDatas(response.data);
    }).catch((error) => {
      setTrialDatas([]);
    });
  }
  else {
    let url = ApiURL + `/show_trial_details/${PlantId}`;
    axios.get(url).then((response) => {
      setTrialDatas(response.data);
    }).catch((error) => {
      setTrialDatas([]);
    });
  }

};


const gettrialsDetails = () => {
  let url = ApiURL + `/show_trial_details/${userlogoutdata}`;
  axios.get(url,{
    headers: {
      Authorization: `Bearer ${access_token}`,
      
    }
  }
).then((response) => {
    const newData = [...response.data];
    // Call the callback function with the updated data
    onSubmit(newData);
    
  }).catch((error) => {
    setTrialDatas([]);
  });
};


const getTrialAreaDetails = (areas) => {
  const areaId = areas;
  if(userlogoutdata){
    let url = ApiURL + `/show_trial_details_area/${areaId}/${userlogoutdata}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
      
      }
    }
).then((response) => {
      const newData = [...response.data];
      // Call the callback function with the updated data
      onSubmit(newData);
    }).catch((error) => {
      setTrialDatas([]);
    });
  }
  else{
    let url = ApiURL + `/show_trial_details_area/${areaId}/${plantId}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
        
      }
    }
).then((response) => {
      const newData = [...response.data];
      // Call the callback function with the updated data
      onSubmit(newData);
     
    }).catch((error) => {
      setTrialDatas([]);
    });
  }

};
const getTrialYearDetails = (years) => {
  const year = years;
  if (userlogoutdata){
    let url = ApiURL + `/show_trial_details_yearselect/${year}/${userlogoutdata}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      const newData = [...response.data];
      // Call the callback function with the updated data
      onSubmit(newData);
      
    }).catch((error) => {
      setTrialDatas([]);
    });
  }
  else{
    let url = ApiURL + `/show_trial_details_yearselect/${year}/${plantId}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
        
      }
    }
).then((response) => {
      const newData = [...response.data];
      // Call the callback function with the updated data
      onSubmit(newData);
    }).catch((error) => {
      setTrialDatas([]);
    });
  }

};


const getTrialTablePlantDetails = (plants) => {
  const plantId = plants;
  let url = ApiURL + `/show_trial_plant/${plantId}`;
  axios.get(url).then((response) => {
    setTrialTableDatas(response.data);
  }).catch((error) => {
    setTrialTableDatas([]);
  });
};

const gettrialYears = () => {
  let url = ApiURL + '/show_trial_details_year';
  axios.get(url).then((response) => {
    const yearOptionsWithAllYear = [{ year: 'All Year' }, ...response.data];
    setYearsOptions(yearOptionsWithAllYear);
  }).catch((error) => {
    setYearsOptions([]);
  });
};

const gettrialTableDetails = (newTrail) => {
  if (userlogoutdata) {
    let url = ApiURL + `/show_trial/${userlogoutdata}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      const newData = [...response.data, newTrail];
      setTrialTableDatas(newData);
    }).catch((error) => {
      setTrialTableDatas([]);
    });
  }
  
};



const filterselectedarea =localStorage.getItem('area') === 'null' ? null : localStorage.getItem('area');
const filterselectedyear = localStorage.getItem('selectedYear');

const handleTrialBasedOnFilters = (newTrail,newtrialdetails) => {

  if (filterselectedyear !== '' && filterselectedarea === '12') {
      getTrialPlantDateDetails(userlogoutdata, filterselectedyear);
      // getTrialTablePlantDetails(userlogoutdata);
  } else if (filterselectedarea !== null && filterselectedyear !== '' && filterselectedarea !== '12' && filterselectedyear !== 'All Year') {
      getTrialAreaDateDetails(filterselectedarea, filterselectedyear, newTrail)
         // Data includes both the fetched data and newTrail
    
      
      // getTrialAreaDateDetails(filterselectedarea, filterselectedyear);
      gettrialTableDetails(newtrialdetails)
  } else if (filterselectedarea === null && filterselectedyear !== '' && filterselectedyear !== 'All Year') {
      getTrialYearDetails(filterselectedyear)

      // onHandleTrial();
  } else if (filterselectedarea !== null && filterselectedyear === '' && filterselectedarea !== '12') {
      getTrialAreaDetails(filterselectedarea)
      // onHandleTrial();
  } 
  else if (filterselectedarea === null && filterselectedyear !== '' && filterselectedyear === 'All Year') {
    gettrialsDetails();

    // onHandleTrial();
} else if (filterselectedarea !== null && filterselectedyear === '' && filterselectedarea === '12') {
    gettrialsDetails();
    // onHandleTrial();
} 
  else if(filterselectedyear === 'All Year' && filterselectedarea !==null){
    getTrialAreaDetails(filterselectedarea)
  }
  else {
     
      gettrialsDetails();
      // onHandleTrial();
  }
};
const handleSubmit = async() => {
    if (
      !selectedDate ||
      !area ||
      !description ||
      (!zone && !inputValueZone) ||
      (!imagePath && !selectedRowData) ||
      (remarksValue !== 'Others' && remarksValue !== 'Not access' && !xrfNumber) ||
  (remarksValue !== 'Others' && remarksValue !== 'Not access' && !Ni)
  
    ) {
      if (!selectedDate) setErrorMessageDate('Please select a date.');
      if (!area) setErrorMessage('Please select an area.');
      if (!description) setErrorMessageDescription('Please enter a description.');
      if (!zone && !inputValueZone) setErrorMessageZone('Please select a zone.');
      if (!imagePath && !selectedRowData) setErrorMessageImage('Please add a image');
      if (remarksValue !== 'Others' && remarksValue !== 'Not access' && !xrfNumber) setErrorMessageXrf('Please enter an XRF reference number.');
      if (remarksValue !== 'Others' && remarksValue !== 'Not access' && !Ni) setErrorMessageNi('Please enter Ni%.');
      if (!remarksValue) setErrorMessageRemark('Please select a remark.');
      if (remarksValue !== 'Accessible' && !comments) setErrorMessageComments('Please enter comments.');
      if (remarksValue === 'Others' || remarksValue === 'Not access') {
        setErrorMessageXrf('');
        setErrorMessageNi('');
      }
      
      return;
    }else {
      setLoading(true);
    }

};


  useEffect(() => {
    const fetchData = async () => {
    if (currenttrialData) {
     const selectedArea = areaData.find((option) => option.area === currenttrialData[0].area);
          setArea(selectedArea ? selectedArea.id : null);
          if(selectedArea){
            try {
              const response = await getzoneDetailsUpdate(selectedArea.id);
              const selectedZone = response.find((option) => option.zone === currenttrialData[0].zone);
              setInputValueZone(selectedZone || null);
              if (!isInputValueZoneInitialized) {
                setInputValueZoneInitialized(true);
              }
             
            } catch (error) {
              // Handle errors if needed
              console.error("Error fetching zone details:", error);
            }
          };
          
          const xrfNumber = currenttrialData[0]?.xrf_number || ''; // Ensure xrfNumber is defined
          const ni = currenttrialData[0]?.ni || '';
          const comments = currenttrialData[0]?.comments || '';
      
           // Convert date string to Date object
           const dateTimeString = currenttrialData[0]?.formatted_date || ''; // Use default value if date is invalid
    setSelectedDate(dateTimeString);
      
          // Set the state for xrfNumber, ni, and comments
          setXrfNumber(xrfNumber);
          setNi(ni);
          setComments(comments);
          const selectedRemarks = currenttrialData[0]?.remark || '';
         setRemarksValue(selectedRemarks);
    } else {
      // If selectedRowData is null, it's an add operation
      
      setArea(null);
      setInputValueZone(null);
      setXrfNumber('');
      setNi('');
      setComments('');
      setRemarksValue('');
      setSelectedDate(null);
    }
  };
  fetchData();
  }, [currenttrialData,isInputValueZoneInitialized]);

  useEffect(() => {
    if (isInputValueZoneInitialized) {
      
      // Additional logic or state updates that depend on inputValuePlant
    }
  }, [inputValueZone, isInputValueZoneInitialized]);

//   useEffect(() => {
//     getimageDetails();
   
   
    
//   }, []);

//   const getimageDetails = () => {
//     let url = ApiURL + '/show_image';
//     axios.get(url,{
//       headers: {
//         Authorization: `Bearer ${access_token}`,
//         secret_key:secret_key
//       }
//     }
// ).then((response) => {
//       setImageFileData(response.data);
//     }).catch((error) => {
//       setImageFileData([]);
//     });
//   };
  const deleteTrialImage = (imageName) => {
    const url = ApiURL + `/delete_image/${encodeURIComponent(imageName)}`; // Replace with your backend API endpoint to delete area
    axios
    .delete(url)
      .then((response) => {
  
      })
      .catch((error) => {
        // Handle error
        console.error('Failed to delete image:', error);
      });
  };
  useEffect(() => {
    getareaDetails();
    
  }, []);
 
  
  const getareaDetails = () => {
    if(userlogoutdata){
      let url = ApiURL + `/show_area_names/${userlogoutdata}`;
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
).then((response) => {
        setAreaData(response.data);
      }).catch((error) => {
        setAreaData([]);
      });
    }
else{
 
  let url = ApiURL + `/show_area_names/${PlantId}`;
  axios.get(url,{
    headers: {
      Authorization: `Bearer ${access_token}`,
      
    }
  }
).then((response) => {
    setAreaData(response.data);
  }).catch((error) => {
    setAreaData([]);
  });
}
  };

  const getzoneDetails = (areazone) => {
    const zoneId=areazone
    let url = ApiURL + `/show_zone/${zoneId}`;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
     
      }
    }
).then((response) => {
      setZoneData(response.data);
    }).catch((error) => {
      setZoneData([]);
    });
  };
  const getzoneDetailsUpdate = async (area) => {
    const zoneId = area;
    const url = ApiURL + `/show_zone/${zoneId}`;
  
    try {
      const response = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
);
      setZoneData(response.data);
      return response.data; // Return the fetched data
    } catch (error) {
      setZoneData([]);
      throw error; // Re-throw the error to handle it outside the function
    }
  };
  
  const getImageFileDetails = async(response) => {
    const imgName=response;
    let url = ApiURL +`/show_file_name/${imgName}`;
    const response1=await axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
)
if(response1.status===200){
  setFileData(response1.data[0].id);
  return response1.data[0].id
}

    
  };
  const getDescriptionDetails=(areaDescription)=>{
    let url = ApiURL + `/get_description/${areaDescription}`;
    return axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
)
    .then((response) => {
      const datadescription = response.data;
      setDescriptionData(response.data);
      return datadescription;
    })
    .catch((error) => {
      setDescriptionData([]);
    });
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValueZone({ id: inputValueZone?.id || null, zone: newInputValue });
    setInputZone(newInputValue);
  };
const renderZoneField = () => {
  if(selectedRowData){
    return(
      <div>
         <Autocomplete
         className="autocomplete-nis"
         size="small"
      value={inputValueZone}
      onChange={(event, newValue) => {
        setInputValueZone(newValue);
        setInputZone(newValue ? newValue.zone : ''); // Clear the input when an option is selected
      }}
      inputValue={inputZone}
      onInputChange={handleInputChange}
      options={zoneData}
      getOptionLabel={(option) => option.zone}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Type something"
          variant="outlined"
          fullWidth
        />
      )}
    />
      </div>
    )
  }
  else{
    return(
    <div>
      <Autocomplete
className="autocomplete-nis"
size="small"
value={zone}
onChange={(event, newValue) => {
  setZone(newValue);
}}

filterOptions={(options, params) => {
  const filtered = filter(options, params);

  // Suggest creating a new option if the user input doesn't match any options
  if (params.inputValue !== '' && !filtered.length) {
    filtered.push({
      inputValue: params.inputValue,
      title: `Add "${params.inputValue}"`,
    });
  }

  return filtered;
}}
selectOnFocus
clearOnBlur
handleHomeEndKeys
id="my-autocomplete"
options={zoneData}
getOptionLabel={(option) => {
  if (typeof option === 'string') {
    return option;
  }
  if (option.inputValue) {
    return option.inputValue;
  }
  return option.zone;
}}
renderOption={(props, option) => (
  <li {...props}>
    {option.inputValue ? `Add "${option.inputValue}"` : option.zone}
  </li>
)}
renderInput={(params) => (
  <TextField
    {...params}
    label="Select or Add Option"
    variant="outlined"
    fullWidth
  />
)}
style={{ marginBottom: 16 }}
/>
    </div>)
  }
};
const today = new Date().toISOString().split('T')[0];
const handleSubmissionInCapture = () => {
  setCaptureActive(false);
  setCloudActive(false)
  setOpenDialog(true); // Set this to true if you want to open the dialog
  // Navigate back to the view
};
useEffect(()=>{
  const submitdata=async()=>{
  const element = currenttrialData ? currenttrialData[0] : null;
  const trialDetailsId = element ? element.id : null;
  const trialId=element?element.trialid:null;
  const imgId=element?element.img_id:null;
  let imgvalue;
  if(imgfile!==undefined){
    const imageData = await generateImagesArray(imgfile);
    const images = Array.isArray(imageData) ? imageData : Array.from(imageData);
    imgvalue=await uploadBlobImagesAPI(images);
  }
  const newTrail=trialDetailsId
  ? {
    id:trialDetailsId,
    area,
    description,
    zone: inputValueZone.zone,
    zone_id:inputValueZone.id,
    xrfNumber,
    Ni,
    remarksValue,
    comments,
    selectedDate,
    fileData:imgId,
    trialid:trialId,
    name:userDet.id,
    }
  :zone.id ?{
    area,
    description,
    zone: zone.id,
    xrfNumber,
    Ni,
    remarksValue,
    comments,
    selectedDate,
    fileData:imgvalue,
    name:userDet.id,
  } 
  : {
    area,
    description,
    inputZone:zone.inputValue,
    xrfNumber,
    Ni,
    remarksValue,
    comments,
    selectedDate,
    fileData:imgvalue,
    name:userDet.id,
    };
if(imgvalue || imgId){
  if(trialDetailsId){
    axios
    .post(ApiURL + '/update_zone', newTrail,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
    .then((response) => {
    axios
    .post(ApiURL + '/update_trial', newTrail,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
    .then((response) => {
      // Handle successful response
      onHandleTrial();
      axios
      .post(ApiURL + '/update_trial_details', newTrail,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
      .then((response) => {
        if(response.status===200){
          // const updatetrail=response.data.identifiers[0]
  
  
  handleTrialBasedOnFilters(newTrail,newTrail);
  
       
        onShowAlertMessage('inspection updated successfully');
        }
        
        // onSubmit(newTrail);
        // onHandleTrialDetails();
        // onShowAlertMessage('trial updated successfully');
      })
      .catch((error) => {
        // Handle error
        console.error('Failed to save area:', error);
      });
    })
    .catch((error) => {
      // Handle error
      console.error('Failed to save area:', error);
    });
  })
    .catch((error) => {
      // Handle error
      console.error('Failed to save area:', error);
    });
    setArea(null);
    setDescription('');
    setInputValueZone(null);
    setXrfNumber('');
    setNi('');
    setComments('');
    setRemarksValue('');
    setSelectedDate(null);
    setImageData('');
    setImagePath('');
   onclose();
    
  
  }else{
  
  if(zone.id){
        axios
    .post(ApiURL + '/create_trial', newTrail,{
      headers: {
        Authorization: `Bearer ${access_token}`,
        
      }
    }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
    .then((response) => {
      newTrail.trialid=response.data.identifiers[0].id;
      const updatetraildetails=response.data.identifiers[0]
      onHandleTrial();
     
      axios
      .post(ApiURL + '/create_trial_details', newTrail,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
      .then((response) => {
       
        if(response.status===200){
          const updatetrail=response.data.identifiers[0]
  
  
  handleTrialBasedOnFilters(newTrail,newTrail);
  
       
        onShowAlertMessage('inspection created successfully');
        }
        
      })
      .catch((error) => {
        // Handle errors
        console.error('Failed to save area:', error);
      });
      
    })
    .catch((error) => {
      // Handle errors
      console.error('Failed to save area:', error);
    });
   
    setArea(null);
    setDescription('');
    setZone('');
    setXrfNumber('');
    setNi('');
    setComments('');
    setRemarksValue('');
    setImageData('');
    setImagePath('');
    setSelectedDate(null);
  onclose();
     // Show the table after submitting the form
  }
  else{
    axios
    .post(ApiURL + '/create_zone', newTrail,{
      headers: {
        Authorization: `Bearer ${access_token}`,
    
      }
    }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
    .then((response) => {
      newTrail.zone=response.data.identifiers[0].id;
    axios
    .post(ApiURL + '/create_trial', newTrail,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
    .then((response) => {
      newTrail.trialid=response.data.identifiers[0].id;
      onHandleTrial();
      axios
      .post(ApiURL + '/create_trial_details', newTrail,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
      .then((response) => {
        if(response.status===200){
          const updatetrail=response.data.identifiers[0]
  
  
  handleTrialBasedOnFilters(newTrail,newTrail);
  
       
        onShowAlertMessage('inspection inserted successfully');
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Failed to save area:', error);
      });
      
    })
    .catch((error) => {
      // Handle errors
      console.error('Failed to save area:', error);
    });
  })
    .catch((error) => {
      // Handle errors
      console.error('Failed to save area:', error);
    });
   
    setArea(null);
    setDescription('');
    setZone('');
    setXrfNumber('');
    setNi('');
    setComments('');
    setRemarksValue('');
    setImageData('');
    setImagePath('');
    setSelectedDate(null);
  onclose();
  }
  
  }
}
else{
  console.error('Image value is missing. Request cannot be completed.');
}

setErrorMessage('');
setErrorMessageDescription('');
setErrorMessageZone('');
setErrorMessageXrf('');
setErrorMessageNi('');
setErrorMessageRemark('');
setErrorMessageComments('');
setErrorMessageImage('');
setErrorMessageDate(null);
setLoading(false);


  }
  if(loading){
   submitdata();
  }
},[loading])

  return (    
     <div>
         {loading &&(

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',position:'fixed',top:0,width:'100%',background:'#000000ab',zIndex:'9999' }}>
<CircularProgress /> 
</div>
      
    )}
      <Dialog open={open || openDialog} onClose={handleClose} fullWidth maxWidth="xs">
  
      {currenttrialData.length>0?(
            <DialogTitle style={{ paddingBottom: 0 }}>Edit Inspection</DialogTitle>
          ):(
    <DialogTitle style={{ paddingBottom: 0 }}>New Inspection</DialogTitle>
          )}
    
    
        
            <DialogContent  className={`dialog-content ${isMobileView ? 'show-scroll' : (isCursorInsideDialog ? 'show-scroll' : '')}`}
    
         
    
    onMouseEnter={() => {
    
      setIsCursorInsideDialog(true);
    
    }}
    
    onMouseLeave={() => {
    
      setIsCursorInsideDialog(false);
    
    }}>
        {captureActive ?(
          <CaptureImage handleSubmission={handleSubmissionInCapture} setUploadedFile={setUploadedFile} dataURLtoFile={dataURLtoFile}/>
      ):(<div>
        {cloudActive && !captureActive &&(
        <div>
         <canvas
              ref={canvasRef}
              style={{ display: 'none' }}
            />
               <video
              ref={videoRef}
              style={{ width: '100%', height: 'auto' }}
              autoPlay
              playsInline
            />
    <input  id="upload-image" multiple type="file" style={{ display: 'none' }} onChange={setUploadedFile}    ref={fileInputRef} />
    {isMobileView ? (
          // Render both buttons for mobile view
          <div>
            
            <button onClick={handleCaptureGallery}>Select from Gallery</button>
          </div>
        ) : (
          // Render only the "Select from Gallery" button for desktop view
          <button onClick={handleCaptureGallery}>Select from Gallery</button>
        )}
    
        </div>
    
      )}
    
           {!cloudActive && !captureActive && (  
            <div> 
            <div>
                {errorMessageImage && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageImage}</p>}
      <IconButton color="primary" component="span" onClick={handleCloudClick}>
        <CloudUploadIcon />
      </IconButton>
      {(selectedRowData || imageData) && (
      <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
    )}
      </div> 
      <div> 
      {(imagePath) && (
         <img
       src={imagePath}
       alt="Selected Image"
       style={imageStyle}
     />
    )}
    
      </div> 
      </div> 
          )}
                <InputLabel style={modalInputLabelStyle}>
    
    Date
    
    </InputLabel>
    <div style={{marginBottom:"16px"}}>
    {errorMessageDate && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageDate}</p>}
    <TextField
    size="small"  className="input-nis"
      fullWidth
      id="date"
      type="date"
      value={selectedDate}
      onChange={handleDateChange}
      inputProps={{ max: today }}
    />
    {errorMessage && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessage}</p>}
    </div>
    
            <div style={{marginBottom:"16px"}}></div>
                <FormControl fullWidth style={{ marginBottom: 20 }}>
                  <InputLabel id="area-label" shrink={Boolean(area)} style={modalInputLabelStyle}>Area</InputLabel>
                  <Select labelId="area-label" className='select-nis' size="small" value={area} onChange={handleAreaChange}>
                    {areaData.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.area}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <InputLabel style={modalInputLabelStyle}>
                  Description
                </InputLabel>
                {errorMessageDescription && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageDescription}</p>}
                <TextField size="small"  className="input-nis"
                  value={description}
                  multiline
                  onChange={handleDescriptionChange}
                  fullWidth
                  style={{ marginBottom: 16, border: '1px solid #ccc', borderRadius: 4, padding: 8}}
                  disabled={true}
                />
           <InputLabel style={modalInputLabelStyle}>Zone</InputLabel>
           {errorMessageZone && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageZone}</p>}
            {renderZoneField()}
    
            
                    <InputLabel style={modalInputLabelStyle}>
                  XRF Reference Number
                </InputLabel>
                {errorMessageXrf && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageXrf}</p>}
                <TextField size="small"  className="input-nis"
                  value={xrfNumber}
                  onChange={handleXrfNumberChange}
                  fullWidth
                  style={{ marginBottom: 16 }}
                  disabled={remarksValue === 'Others' || remarksValue === 'Not access'}
                />
        
                <InputLabel style={modalInputLabelStyle}>
                  Ni%
                </InputLabel>
                {errorMessageNi && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageNi}</p>}
                <TextField size="small"  className="input-nis"
                  value={Ni}
                  onChange={handleNiChange}
                  fullWidth
                  style={{ marginBottom: 16 }}
                  disabled={remarksValue === 'Others' || remarksValue === 'Not access'}
                  
                />
    
    
                <InputLabel id="remarks-label" style={modalInputLabelStyle}>Remark</InputLabel>
                {errorMessageRemark && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageRemark}</p>}
                <FormControl fullWidth>
                  
                  <Select
                    labelId="remarks-label"
                    value={remarksValue}
                    size="small"
                    onChange={handleRemarksChange}
                    fullWidth
                    className='select-nis'
                    style={{ marginBottom: 16 }}
                  >
                    <MenuItem value="">Select a remark</MenuItem>
                    {remarks.map((remark, index) => (
                      <MenuItem key={index} value={remark}>
                        {remark}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
               
          <>
            <InputLabel style={modalInputLabelStyle}>
              Comments
            </InputLabel>
            {errorMessageComments && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageComments}</p>}
            <TextField
              size="small"
              className="input-nis"
              value={comments}
              onChange={handleCommentsChange}
              fullWidth
              style={{ marginBottom: 16 }}
            />
          </>
          <DialogActions style={{ borderTop: '1px solid #f5f5f5', padding: '10px 20px' }}>
                <Button variant="contained" onClick={handleClose} style={{ marginRight: 10,backgroundColor: '#002060' }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit} style={{backgroundColor: '#002060'}}>
                  Submit
                </Button>
      </DialogActions>
          </div>
      )}
      
              
    </DialogContent>
    
    
            </Dialog>
    
       
        </div>
       
        
);
};

export default AddTrial;

